import { createContext, Dispatch } from "react"
import { format } from 'date-fns';
import { es as esLocale } from 'date-fns/locale';
import Countdown from 'react-countdown';

import { Button } from "@/components/ui/button"
import { toast } from "@/hooks/use-toast"

import { HANDIFY_API_ENDPOINT } from '@/definitions'
import { User } from "@/types"

//
// Access management
//
interface UserContextInterface {
  user:    User
  setUser: Dispatch<User>
}

export const anonymousUser = { "user": "anonymous" };

export const userContext = createContext<UserContextInterface>({user: anonymousUser, setUser: (user: User) => {}});

export async function fetchUser(setUser: Dispatch<User>) {
  fetch(`${HANDIFY_API_ENDPOINT}/api/people/user`, {
    method: 'GET',
    credentials: 'include'
  }).then((response) => {
    if (response.status == 200) {
      response.json().then(data => {
        setUser(data);
      })
      return true;
    } else if (response.status == 403) {
      console.log("ERR fecthing user:", response);
    }
  })
}

export function getCsrfTokenFromCookie() {
  return document.cookie.replace(/(?:(?:^|.*;\s*)csrftoken\s*\=\s*([^;]*).*$)|^.*$/, "$1");
}

//
// Toast messages
//
export function toastOk(msg: string | null, code: string | null = null) {
  let config = {
    title: "OK!",
    description: (
      <>
        {
          msg == null ? <p>El servidor dice:</p> : <p>{msg}</p>
        }
        {
          code != null &&
            <div>
              <div className="mt-2 overflow-auto max-w-[95%] rounded-md bg-blue-950 p-4">
                <div className="font-mono text-white overflow-scroll">{code}</div>
              </div>
              <Button className="mt-2" onClick={() =>{copy_to_clipboard(code)}}>Copiar error al portapapeles</Button>
            </div>
        }
      </>
    )
  };
  toast(config)
}

export function toastErr(msg: string | null, code: string | null = null) {
  let config = {
    title: "Error!",
    description: (<>
      {
        msg == null ? <p>El servidor dice:</p> : <p>{msg}</p>
      }
      {
        code != null &&
          <div>
            <div className="mt-2 overflow-auto max-w-[95%] rounded-md bg-blue-950 p-4">
              <div className="font-mono text-white overflow-scroll">{code}</div>
            </div>
            <Button className="mt-2" onClick={() =>{copy_to_clipboard(code)}}>Copiar error al portapapeles</Button>
          </div>
      }
    </>)
  };
  toast(config)
}

//
// Dumb stuff
//
export function formatClp(x: string | number) {
  return '$ ' + Number(x).toLocaleString('es');
}

export function dictToStr(obj: object | string) {
  return JSON.stringify(obj, null, 2)
}

export function countDown(ts: Date | string) {
  let end = new Date(ts);
  return <div className="text-xl font-mono"><Countdown daysInHours date={end} /></div>;
}

export function formatTimestamp(ts: Date | string, verbose=false) {
  if (verbose) {
    let s = format(ts, "EEEE d' de ' MMMM ' de 'yyyy' a las 'HH:mm", {locale: esLocale});
    return String(s).charAt(0).toUpperCase() + String(s).slice(1);
  }
  return format(ts, "dd/MM/yyyy HH:mm");
}

// https://stackoverflow.com/questions/400212
function copy_to_clipboard(text: string) {
  var input = document.createElement('textarea');
  input.innerHTML = text;
  document.body.appendChild(input);
  input.select();
  var result = document.execCommand('copy');
  document.body.removeChild(input);
  return result;
}
