import { Button } from "@/components/ui/button"

interface GridProps {
    slots:      Array<string>;
    className?: string;
    options:    object;
    setSlots:   (slots: Array<string>) => void;
}

export function GridSelect({slots, setSlots, className, options}: GridProps) {
  return (
    <div className={"grid " + className}>
      {
        Object.entries(options).map(([key, value]) => {
          return <Button key={key} variant="secondary"
            className={
              slots.includes(key) ?
              "flex-col items-center justify-between rounded-md border-2 border-primary bg-lightblue-800 p-2 hover:bg-accent text-blue-400 text-xs font-semibold" :
              "flex-col items-center justify-between rounded-md border-2 bg-transparent p-2 hover:bg-accent hover:text-accent-foreground text-slate-400 text-xs font-normal"
            }
            onClick={e => {
              e.preventDefault();
              let cur_slots = slots;
              const index = cur_slots.indexOf(key);
              if (index > -1) { // If element is in array, remove
                cur_slots.splice(index, 1);
            } else {
              cur_slots.push(key);
            }
            setSlots(cur_slots);
            }}
          >
            {value}
          </Button>
      })
      }
    </div>
  )
}
