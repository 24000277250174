import { useState } from "react"
import { useNavigate } from "react-router"

import { Button } from "@/components/ui/button"
import {
  Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog"

import { CONTACT_EMAIL } from '@/definitions'
import HandifyLogoSimple from '@/components/logo/handify'
import { ChevronsDown as ChevronsDownIcon, MessageCircle as MessageCircleIcon } from "lucide-react"

function ProDialog() {
  const [open, setOpen] = useState(false);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="outline">Quiero saber más</Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>¿Eres un profesional?</DialogTitle>
        </DialogHeader>
        <DialogDescription>
          Escríbenos a <a className="hyperlink" href={`mailto:${CONTACT_EMAIL}`}>{CONTACT_EMAIL}</a> y cuéntanos sobre ti. Tenemos oportunidades!
        </DialogDescription>
      </DialogContent>
    </Dialog>
  );
}

export default function Landing() {
  const navigate = useNavigate();
  return (
    <>
      <div className="flex flex-col justify-between h-[calc(100vh-60px)] bg-slate-900">
        <div className="flex flex-wrap bg-blue-400 w-full pt-10 pb-10 sm:pb-20">
          <div className="flex flex-wrap justify-center min-h-[300px] grow m-4 sm:m-10 gap-10">
            <div className="flex justify-center sm:min-w-[400px] max-w-[500px]">
              <div className="flex flex-col justify-end grid-cols-1 gap-5">
                <div className="text-white text-3xl sm:text-5xl font-semibold">¿Necesitas un arreglo rápido y confiable?</div>
                <div className="text-white text-xl sm:text-2xl">En Handify tenemos la solución. Porque sabemos que cada detalle importa.</div>
              </div>
            </div>
            <div className="flex flex-col gap-4 flex-grow items-center justify-center max-w-[400px] ">
              <div className="flex flex-grow justify-end">
                <HandifyLogoSimple className="max-w-[200px] mx-auto h-auto"/>
              </div>
              <Button className="text-2xl font-semibold max-w-[300px] p-6 m-2" onClick={() => navigate("/book")}>Agenda una cita!</Button>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap justify-center bg-slate-900 w-full p-3">
          <ChevronsDownIcon className="text-slate-400 hover:text-slate-200" onClick={() => console.log("clis")} strokeWidth={1} size={30} />
        </div>
      </div>
      <div className="flex flex-col w-full justify-items-center items-center p-8 pt-12 gap-4">
        <div className="flex items-center gap-4">
          <MessageCircleIcon className="flex-none" size={40} />
          <div className="text-2xl">
            <span className="font-semibold">¿Eres un profesional?</span> Tenemos oportunidades para ti...
          </div>
        </div>
        <ProDialog />
      </div>
    </>
  );
}
