// 3rd party imports
import { useState } from "react"
import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import { format } from 'date-fns';

// UI imports
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import {
  Form, FormControl, FormField, FormItem, FormLabel,
  FormMessage,
} from "@/components/ui/form"
import { Textarea } from "@/components/ui/textarea"
import { Check as CheckIcon } from "lucide-react"
import {
  Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog"

// App imports
import { Ticket, TICKET_STATUS, Uuid } from "@/types"
import { HANDIFY_API_ENDPOINT, SLOTS } from "@/definitions"
import { TicketDetails } from "@/components/ticket"
import { getCsrfTokenFromCookie, toastOk, toastErr } from "@/utils"
import { Title } from "@/components/title"
import LoadingButton from "@/components/loading-button"

function TicketDialogs({ticket}: {ticket: Ticket}) {
  let dialogs = <></>;
  switch (ticket.status) {
    case TICKET_STATUS.OPEN:
      dialogs = <>
        <AcceptPlanDialog className="w-full" uuid={ticket.uuid} customerSlots={ticket.order.slots_list}/>
      </>;
      break;
    case TICKET_STATUS.INPROGRESS:
      dialogs = <>
        <FinalizeTicketDialog uuid={ticket.uuid}/>
      </>
        break;
  };
  return dialogs;
}

function AcceptPlanForm({uuid, customerSlots}: {uuid: Uuid, customerSlots: Array<string>}) {
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date>();
  const acceptPlanFormSchema = z.object({
    time: z.string({
      required_error: "Ingresa hora primera visita",
    }),
  })
  const form = useForm<z.infer<typeof acceptPlanFormSchema>>({
    resolver: zodResolver(acceptPlanFormSchema),
  });

  async function acceptPlan(values: z.infer<typeof acceptPlanFormSchema>) {
    if (!selectedDate) {
      toastErr("Selecciona una fecha", null);
      return;
    }
    setLoading(true);
    let formattedDate = format(selectedDate, "yyyy-MM-dd");
    let dateTime = formattedDate + "T" + values.time + ":00"
    let data = { "first_visit": dateTime };
    await fetch(`${HANDIFY_API_ENDPOINT}/api/tickets/${uuid}/propose`, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify(data),
      headers: {
        'X-CSRFToken': getCsrfTokenFromCookie(),
        'Content-type': 'application/json; charset=UTF-8',
      },
    }).then((response) => {
      setLoading(false);
      if (response.ok) {
        window.location.reload();
        toastOk("El ticket ha sido aceptado.");
      } else {
        response.json().then(data => {
          toastErr(null, JSON.stringify(data, null, 2));
        });
      }
    }).catch((err) => {
      toastErr("El servidor dice", err.message);
    });
  }

  let customer_slots = customerSlots.map(( slot ) => SLOTS[slot]).join(", ");
  return (
    <div>
      <Form { ... form}>
        <form onSubmit={form.handleSubmit(acceptPlan)} className="space-y-5">
          <FormItem>
            <div className="flex justify-center">
              <DayPicker mode="single" selected={selectedDate} onSelect={setSelectedDate}/>
            </div>
          </FormItem>
          <FormField
            control={form.control}
            name="time"
            render={({ field }) => (
            <FormItem>
              <FormLabel>Hora primera visita</FormLabel>
              <FormControl>
                <Input type="time" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
            )
            }/>
          <div className="text-sm mt-4 mb-4">
            Ten en cuenta las preferencias del cliente: <span className="font-bold">{customer_slots}</span>.
          </div>
          <h2 className="font-bold text-center">Aceptar la propuesta</h2>
          <div className="text-sm mt-4 mb-2">
            Al aceptar, serás asignado a este ticket y te comprometes a realizar
            los trabajos una vez que el cliente realice el pago.
          </div>
          <LoadingButton className="w-full" type="submit" text="Entiendo, aceptar" loading={loading}/>
        </form>
      </Form>
          </div>
      )
}

function AcceptPlanDialog({customerSlots, className, uuid}: {customerSlots: Array<string>, className: string, uuid: Uuid}) {
  const [open, setOpen] = useState(false);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild className={className}>
        <Button><CheckIcon />Aceptar propuesta</Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle className="text-center">¿Cuándo empiezas?</DialogTitle>
        </DialogHeader>
        <AcceptPlanForm uuid={uuid} customerSlots={customerSlots} />
      </DialogContent>
    </Dialog>
  )
}

function FinalizeTicketDialog({uuid}: {uuid: Uuid}) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const finalizeFormSchema = z.object({
    partner_closure: z.string({
      required_error: "Comentario final es obligatorio",
    }),
  })

  const form = useForm<z.infer<typeof finalizeFormSchema>>({
    resolver: zodResolver(finalizeFormSchema)
  });


  async function finalizeTicket(values: z.infer<typeof finalizeFormSchema>) {
    setLoading(true);
    let data = {
      "partner_closure": values["partner_closure"]
    };
    await fetch(`${HANDIFY_API_ENDPOINT}/api/tickets/${uuid}/finalize`, {
      method: 'POST',
      body: JSON.stringify(data),
      credentials: 'include',
      headers: {
        'X-CSRFToken': getCsrfTokenFromCookie(),
        'Content-type': 'application/json; charset=UTF-8',
      },
    }).then((response) => {
      if (response.ok) {
        window.location.reload();
        toastOk("El ticket " + uuid + " ha sido finalizado.");
      } else {
        response.json().then(data => {
          toastErr(null, JSON.stringify(data, null, 2));
        });
      }
      setLoading(false);
      setOpen(false);
    }).catch((err) => {
      toastErr("El servidor dice", err.message);
    });
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button className="w-full">
          Finalizar ticket
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Finalizar ticket</DialogTitle>
        </DialogHeader>
        <DialogDescription>
          El ticket se dará por finalizado y el cliente recibirá un email de confirmación.
        </DialogDescription>
        <Form { ... form }>
          <form onSubmit={form.handleSubmit(finalizeTicket)}>
            <FormField
              control={form.control}
              name="partner_closure"
              render={({ field }) => (
              <FormItem>
                <FormLabel>
                  Comentario final
                </FormLabel>
                <FormControl>
                  <Textarea
                    className="min-h-[100px]"
                    placeholder="Comentario de cierre para el cliente"
                    {... field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>)}
              />
            <FormItem className="pt-4 flex justify-end">
              <LoadingButton type="submit" text="Finalizar" loading={loading}/>
            </FormItem>
            </form>
          </Form>
        </DialogContent>
      </Dialog>
  )
}

export function TicketPartner({ticket}: {ticket: Ticket}) {
  let dialogs = <TicketDialogs ticket={ticket} />;
  return (
    <div>
      <Title child={<>Detalle ticket</>}/>
      <div className="flex flex-wrap justify-center gap-7">
        <div className="p-3 font-[family-name:var(--font-geist-sans)]">
          <TicketDetails ticket={ticket} dialogs={dialogs}/>
        </div>
      </div>
    </div>
  )
}
