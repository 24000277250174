import { useNavigate } from "react-router"
import { Button } from "@/components/ui/button"
import HandifyLogoSimple from '@/components/logo/handify'

export function NotFound() {
  const navigate = useNavigate();
  const goToHome = () => {navigate("/book");}
  return (
    <div className="grid justify-items-center items-center min-h-[400px] font-[family-name:var(--font-geist-sans)]">
      <div className="flex flex-col gap-8 row-start-2 justify-items-center items-center">
        <HandifyLogoSimple className="max-w-40 mx-auto h-auto"/>
        <h1 className="max-w-[350px] text-center">Buscamos aquí... pero no hay nada!</h1>
        <Button onClick={goToHome}>Agenda una cita con Handify!</Button>
      </div>
    </div>
  );
}
