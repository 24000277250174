import { useEffect, useState } from "react";

import { HANDIFY_API_ENDPOINT } from "@/definitions"
import { toastErr } from "@/utils"

import Thanks from "@/pages/thanks"

import { CustomerForm, CustomerFormFields } from "@/components/customer-form"
import { FileWithHash } from "@/types"
import { OrderForm, OrderFormFields } from "@/components/order-form"
import { Title } from "@/components/title"

import { Button } from "@/components/ui/button"
import { ArrowLeft } from "lucide-react"

export default function Home() {
  const [step, setStep] = useState(0);
  const [order, setOrder] = useState<OrderFormFields>({});

  function orderOnSubmit(order: OrderFormFields) {
    setOrder(order);
    setStep(1);
  }

  const [visibleElement, setVisibleElement] = useState(
    <OrderForm onSubmit={orderOnSubmit} values={order} />
  );

  useEffect(() => {
    async function customerOnSubmit(values: CustomerFormFields) {
      // Separate data and files
      let data: any = { ...order };
      data["customer"] = values;
      let selected_files = data.files.map((file: FileWithHash) => { return file["file"]; });
      delete data.files;

      // Create a form data with POST data and files
      let form_data = new FormData();
      form_data.append('json', JSON.stringify(data));
      selected_files.map((file: File) => (form_data.append("files", file)));

      await fetch(`${HANDIFY_API_ENDPOINT}/api/tickets/orders/`, {
        method: 'POST',
        body: form_data,
        // headers: { ... },  <-- Apparently only works without headers
      }).then((response) => {
        if (response.ok) {
          setStep(2);
        } else {
          response.json().then(data => {
            toastErr(null, JSON.stringify(data, null, 2));
          }).catch((_err) => {
            toastErr("Algo salió mal! Por favor contáctanos.");
          });
        }
      }).catch((err) => {
        toastErr(null, err.message);
      });
    }
    switch (step) {
      case 0:
        setVisibleElement(
          <OrderForm onSubmit={orderOnSubmit} values={order} />
        );
        break;
      case 1:
        setVisibleElement(<><CustomerForm onSubmit={customerOnSubmit}/></>);
        break;
      case 2:
        setVisibleElement(<Thanks />);
        break;
    }
  }, [step, order])

  return (
    <div className="p-2">
      <Title child={
        step !== 2 ? <>Agenda una cita con Handify!</> : <>Muchas gracias! Estamos revisando tu solicitud.</>
        }/>
      <div className="grid justify-center font-[family-name:var(--font-geist-sans)]">
        <div className="max-w-[330px] sm:max-w-[900px]">
          {
            step === 1 &&
              <Button onClick={() => {setStep(s => {return s-1});}} variant="ghost" className="pb-10"><ArrowLeft />Volver</Button>
          }
          {visibleElement}
        </div>
      </div>
    </div>
  );
}
