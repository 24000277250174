// 3rd party imports
import { useEffect, useState, useRef } from "react"
import { useNavigate } from "react-router-dom"
import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"

// UI imports
import {
  Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle,
} from "@/components/ui/card"
import { Button } from "@/components/ui/button"
import {
  Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader,
  DialogTitle, DialogTrigger,
} from "@/components/ui/dialog"
import { Label } from "@/components/ui/label"
import { Textarea } from "@/components/ui/textarea"
import {
  Accordion, AccordionContent, AccordionItem, AccordionTrigger,
} from "@/components/ui/accordion"
import LoadingButton from "@/components/loading-button"
import { Check as CheckIcon, X as XIcon } from "lucide-react"
import {
  Form,
  FormDescription
} from "@/components/ui/form"

// App imports
import { HANDIFY_API_ENDPOINT, SLOTS } from "@/definitions"
import { formatTimestamp, getCsrfTokenFromCookie, toastErr, toastOk } from "@/utils"
import { Customer, Order, OrderStatus, ORDER_STATUS, Uuid } from "@/types"

import { CategoryBadges } from "@/components/categories"
import { PreviewZoom } from "@/components/preview-zoom"

export function CustomerDetails({customer_details}: {customer_details: Customer}) {
  return (
    <div className="grid grid-cols-1">
      <div>{customer_details.first_name} {customer_details.last_name}</div>
      <div>{customer_details.phone}</div>
      <div>{customer_details.orders.length} pedidos</div>
    </div>
  )
}

function DenyOrderDialog({uuid}: {uuid: Uuid}) {
  const denyFormSchema = z.object({
    comment: z.string().optional()
  })

  const [open, setOpen] = useState(false);

  const commentRef = useRef<HTMLTextAreaElement>(null);

  const [loading, setLoading] = useState(false);

  async function denyOrder(e: React.FormEvent) {
    e.preventDefault();
    setLoading(true);
    let data: {[id:string]: string} = {};
    if (commentRef.current != null) {
      data["reason_denied"] = commentRef.current.value;
    }
    await fetch(`${HANDIFY_API_ENDPOINT}/api/tickets/orders/${uuid}/deny`, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify(data),
      headers: {
        'X-CSRFToken': getCsrfTokenFromCookie(),
        'Content-type': 'application/json; charset=UTF-8',
      },
    }).then((response) => {
      setLoading(false);
      if (response.ok) {
        response.json().then(data => {
          toastOk(`Orden ${data.uuid} rechazada`);
          setOpen(false);
        });
      } else {
        response.json().then(data => {
          toastErr("Algo salió mal...", JSON.stringify(data, null, 2))
        })
      }
    }).catch((err) => {
      toastErr(null, err.message);
    });
  }

  let denyOrderForm = useForm<z.infer<typeof denyFormSchema>>({
    resolver: zodResolver(denyFormSchema),
  })

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="outline">Rechazar</Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Objetar orden</DialogTitle>
        </DialogHeader>
        <Form { ... denyOrderForm}>
          <form className="grid gap-4 py-4" onSubmit={denyOrder}>
            <Textarea
              id="material_name"
              className="col-span-3"
              placeholder="Razón de rechazo (no será enviada al cliente)"
              ref={commentRef}
            />
            <LoadingButton className="flex" type="submit" text="Rechazar" loading={loading} />
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}


export function OrderDetails({order}: {order: Order}) {
  let slots = order.slots_list.map(( slot ) => SLOTS[slot]).join(", ");

  const navigate = useNavigate()

  const [loading, setLoading] = useState(false);
  const acceptOrder = (e: React.MouseEvent) => {
    e.preventDefault();
    setLoading(true);
    navigate(`/orders/accept`, {state: {"order": order} });
  };
  const goToTicket = (e: React.MouseEvent) => {
    e.preventDefault();
    setLoading(true);
    navigate(`/tickets`, {state: {"ticket": order.ticket} });
  };

  const OrderStatus = ({status}: {status: OrderStatus}) => {
    switch (status) {
      case ORDER_STATUS.ACCEPTED: {
        return (
          <CardFooter className="flex justify-between">
            <LoadingButton className="w-full" onClick={goToTicket} text="Ver ticket" loading={loading} />
          </CardFooter>
        );
      }
      case ORDER_STATUS.DENIED: {
        return (
          <CardFooter className="flex justify-between">
            Orden rechazada
          </CardFooter>
        );
      }
      case ORDER_STATUS.BACKLOG: {
        return (
          <CardFooter className="flex justify-between">
            <DenyOrderDialog uuid={order.uuid}/>
            <LoadingButton onClick={acceptOrder} text="Revisar" loading={loading} />
          </CardFooter>
        );
      }
    }
  }

  const OrderStatusIcon = ({status}: {status: OrderStatus}) => {
    switch (status) {
      case ORDER_STATUS.ACCEPTED: {
        return <CheckIcon color="green"/>;
      }
      case ORDER_STATUS.DENIED: {
        return <XIcon color="red"/>;
      }
      default:
        return <></>;
    }
  };

  const backgroundColor = (status: OrderStatus) => {
    switch (status) {
      case ORDER_STATUS.ACCEPTED: {
        return "bg-green-100";
      }
      case ORDER_STATUS.DENIED: {
        return "bg-red-100";
      }
    }
  };

  return (
    <Card className={`${backgroundColor(order.status)}`}>
      <CardHeader>
        <CardTitle className="lone">
          <div className="flex items-center">
            <OrderStatusIcon status={order.status}/>
            <>{order.district}</>
          </div>
        </CardTitle>
        <CardDescription>{order.uuid}</CardDescription>
        <CardDescription>Pedido recibido en {formatTimestamp(order.created_at)}</CardDescription>
      </CardHeader>
      <CardContent>
        <CategoryBadges className="max-w-[300px]" categories={order.categories}/>
      </CardContent>
      <div className="flex flex-wrap justify-between gap-1">
        <CardContent>
          <CardTitle className="lone">{order.customer.email}</CardTitle>
          <CardDescription>
            <CustomerDetails customer_details={order.customer} />
          </CardDescription>
        </CardContent>
        <CardContent className="max-w-[200px]">
          <CardTitle className="lone">Preferencias</CardTitle>
          <CardDescription> {slots} </CardDescription>
        </CardContent>
      </div>
      {
        order.reason_denied &&
          <CardContent>
            <CardTitle className="lone">Razón rechazo</CardTitle>
            <CardDescription>{order.reason_denied}</CardDescription>
          </CardContent>
      }
      <CardContent className="flex-grow min-w-[300px]">
        <Accordion type="multiple">
          <AccordionItem className="border-none" value="item-desc">
            <AccordionTrigger>
              <CardTitle className="lone"> Ver descripción </CardTitle>
            </AccordionTrigger>
            <AccordionContent>
              <CardDescription className="text-justify max-w-[500px]">
                {order.description}
              </CardDescription>
            </AccordionContent>
          </AccordionItem>
          { order.media && order.media.length > 0 &&
          <AccordionItem className="border-none" value="item-media">
            <AccordionTrigger>
              <CardTitle className="lone">
                Ver media
              </CardTitle>
            </AccordionTrigger>
            <AccordionContent>
              <div className="flex justify-center mt-5 max-w-[500px]">
                <PreviewZoom thumbnail={true} urls={order.media} />
              </div>
            </AccordionContent>
          </AccordionItem>
          }
        </Accordion>
      </CardContent>
      <OrderStatus status={order.status}/>
    </Card>
  )
}
