import { useContext, useState } from "react"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"

import { toastErr, userContext } from "@/utils"
import { Ticket, Uuid } from "@/types"
import { HANDIFY_API_ENDPOINT } from "@/definitions"
import { NotFound } from "@/pages/not-found"
import { TicketCustomer } from "@/components/ticket-customer"
import { TicketStaff } from "@/components/ticket-staff"
import { TicketPartner } from "@/components/ticket-partner"
import { TicketsTable } from "@/components/tickets-all"

export default function TicketView() {
  const searchParams = useSearchParams()[0];
  const { state } = useLocation();
  const user = useContext(userContext).user;
  const navigate = useNavigate();
  const [ticket, setTicket] = useState<Ticket>();
  const [fetched, setFetched] = useState<boolean>(false);

  // There are 5 cases
  // - there's nothing in state -> go to general view
  // - there's already a ticket in state -> just go
  // - there's a ticket UUID in state -> fetch ticket and go
  // - there's a ticket UUID in URL -> fetch ticket and go
  // - there's a token in query params -> go to customer view

  let token = searchParams.get("token");

  const fetchTicket = async (uuid: Uuid) => {
    await fetch(`${HANDIFY_API_ENDPOINT}/api/tickets/${uuid}`, {
      method: 'GET',
      credentials: 'include'
    }).then((response) => {
      if (response.ok) {
        response.json().then(data => {
          let t: Ticket = { ...data };
          setTicket(t);
        })
      }
    }).catch((err) => {
      toastErr("El servidor dice", err.message);
    });
  }

  // If user is here with the ticket token, go the the customer view
  if (token) {
    return <TicketCustomer token={token}/>
  }

  // Sadly, doesn't work on production for some reason.
  // if (!ticket && state) {
  //   if ("ticket" in state) {
  //     setTicket(state.ticket);
  //     state["uuid"] = state.ticket.uuid;
  //   } else if ("uuid" in state && !fetched) {
  //     fetchTicket(state.uuid);
  //     setFetched(true);
  //   }
  // }
  // if (!ticket && uuid && !fetched) {
  //   fetchTicket(uuid);
  //   setFetched(true);
  // }

  // We have the ticket, now clear the ticket from state, so that on next
  // reload info is fetched again with changes.
  // if (ticket && state) {
  //   delete state["ticket"];
  // }

  if (state) {
    if ("uuid" in state && !fetched) {
      fetchTicket(state.uuid);
      setFetched(true);
    }
  }

  if (!("access" in user)) {
    navigate("/login?next=/tickets");
    return <></>;
  } else {
    switch (user.access) {
      case "staff":
        if (ticket) {
          return <TicketStaff ticket={ticket} />
        } else {
          return <TicketsTable />
        }
      case "partner":
        if (ticket) {
          return <TicketPartner ticket={ticket} />
        } else {
          return <TicketsTable />
        }
      case "public":
        return <NotFound />;
    }
  }
}
