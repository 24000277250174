import { useContext } from "react"
import { useNavigate } from "react-router-dom"

import {
  LogOut as LogOutIcon,
  TicketCheck as TicketCheckIcon,
  ClipboardList as ClipboardListIcon,
  ContactRound as ContactRoundIcon,
  Users as UsersIcon,
  Settings as SettingsIcon,
} from "lucide-react"

import { Button } from "@/components/ui/button"
import {
  DropdownMenu, DropdownMenuContent, DropdownMenuGroup, DropdownMenuItem,
  DropdownMenuLabel, DropdownMenuPortal, DropdownMenuSeparator,
  DropdownMenuShortcut, DropdownMenuSub, DropdownMenuSubContent,
  DropdownMenuSubTrigger, DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"

import {
  anonymousUser, getCsrfTokenFromCookie, userContext, toastErr,
} from "@/utils"
import { HANDIFY_API_ENDPOINT } from "@/definitions"
import { LoggedInUser } from "@/types"

export default function UserMenu() {
  const {user, setUser} = useContext(userContext);
  const navigate = useNavigate();
  const logout = () => {
    fetch(`${HANDIFY_API_ENDPOINT}/auth/logout/`, {
      credentials: 'include',
      method: 'GET',
      headers: {
        'X-CSRFToken': getCsrfTokenFromCookie(),
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
    }).then((response) => {
      navigate("/");
      setUser(anonymousUser);
    }).catch((err) => {
      toastErr(null, err.message)
    });
  }

  if (!("email" in user)) {
    return <Button
      variant="ghost"
      className="text-slate-300"
      onClick={() =>{navigate("login")}}>
      Iniciar sesión
    </Button>
  } else {
    let logged_in = user as LoggedInUser;
    return (
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" className="text-slate-300">{logged_in.email}</Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-56">
          <DropdownMenuLabel>Mi cuenta</DropdownMenuLabel>
          <DropdownMenuItem disabled>
            <SettingsIcon />
            <span>Configuración</span>
          </DropdownMenuItem>
          <DropdownMenuSeparator />
          <DropdownMenuGroup>
            <DropdownMenuItem onClick={() => {navigate("/orders")}}>
              <ClipboardListIcon />
              <span>Backlog</span>
            </DropdownMenuItem>
            <DropdownMenuItem onClick={() => {navigate("/tickets/all", {state: {}})}}>
              <TicketCheckIcon />
              <span>Tickets</span>
            </DropdownMenuItem>
            <DropdownMenuItem disabled>
              <ContactRoundIcon />
              <span>Clientes</span>
            </DropdownMenuItem>
          </DropdownMenuGroup>
          <DropdownMenuSeparator />
          <DropdownMenuGroup>
            <DropdownMenuItem disabled>
              <UsersIcon />
              <span>Equipo</span>
            </DropdownMenuItem>
          </DropdownMenuGroup>
          <DropdownMenuSeparator />
          <DropdownMenuItem onClick={logout}>
            <LogOutIcon />
            <span>Cerrar sesión</span>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    )
  }
}
