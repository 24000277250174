import { Link } from "react-router"

import UserMenu from "@/components/usermenu"
import { HandifyLogoFull } from '@/components/logo/handify'

function Header() {
  return (
    <header className="w-full bg-blue-400 ">
      <div className="p-3 flex items-center min-h-[60px] gap-3">
        {/* Site branding */}
        <div className="grow ">
          <div className="align-middle max-w-[100px]">
            <Link to="/" className="block">
              <HandifyLogoFull/>
            </Link>
          </div>
        </div>
        <div>
          <UserMenu />
        </div>
      </div>
    </header>
  )
}

export default Header;
