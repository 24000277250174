// 3rd party imports
import { useEffect, useState, useRef, Dispatch } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm, SubmitHandler } from "react-hook-form"

// UI imports
import { X as XIcon } from "lucide-react"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { Button } from "@/components/ui/button"
import {
  Form, FormControl, FormDescription, FormField, FormItem, FormLabel,
  FormMessage,
} from "@/components/ui/form"
import {
  Table, TableBody, TableCaption, TableCell, TableFooter, TableHead,
  TableHeader, TableRow,
} from "@/components/ui/table"
import {
  Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader,
  DialogTitle, DialogTrigger,
} from "@/components/ui/dialog"
import {
  Select, SelectContent, SelectItem, SelectTrigger, SelectValue,
} from "@/components/ui/select"
import { Textarea } from "@/components/ui/textarea"
import { Checkbox } from "@/components/ui/checkbox"

// App imports
import { CATEGORIES, SLOTS, HANDIFY_API_ENDPOINT } from "@/definitions"
import { OrderDetails, CustomerDetails } from "@/components/order"
import LoadingButton from "@/components/loading-button"
import { PreviewZoom } from "@/components/preview-zoom"
import { GridSelect } from "@/components/grid-select"
import { Title } from "@/components/title"
import { Forbidden } from "@/pages/forbidden"
import { Loading } from "@/pages/loading"
import { formatClp, getCsrfTokenFromCookie, toastErr } from "@/utils"
import { Material, Materials, Order, Partner, Ticket } from "@/types"

interface AcceptOrderFormProps {
  partners: Array<Partner>;
  materials: Materials;
  order: Order;
  setMaterials: Dispatch<Materials>;
  onSubmit: SubmitHandler<z.infer<typeof acceptOrderFormSchema>>;
  loading: boolean
}

function AcceptOrderForm({partners, order, materials, setMaterials, onSubmit, loading}: AcceptOrderFormProps) {
  const form = useForm<z.infer<typeof acceptOrderFormSchema>>({
    resolver: zodResolver(acceptOrderFormSchema),
    defaultValues: {
      partners: [],
      categories: order.categories,
    },
  })
  let customer_slots = order.slots_list.map(( slot ) => SLOTS[slot]).join(", ");
  let categories_options: {[id:string]: string} = {}
  Object.entries(CATEGORIES).map(([key, value]) => {
    categories_options[key] = value['label'];
  });

  const [cats, _setCats] = useState(order.categories);

  return (
    <div className="max-w-[600px]">
      <Form { ... form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-5">
          <FormField
            control={form.control}
            name="manager_comment"
            render={({ field }) => (
              <FormItem>
                <div className="flex flex-wrap justify-between gap-8">
                  <div className="flex-none pr-0 mr-0">
                    <FormLabel>Datos cliente</FormLabel>
                    <FormDescription><>Comuna: {order.district}</></FormDescription>
                    <FormDescription><CustomerDetails customer_details={order.customer}/></FormDescription>
                  </div>
                  <div className="flex-grow min-w-[300px]">
                    <FormLabel>Descripción trabajos</FormLabel>
                    <FormDescription className="text-justify">{order.description}</FormDescription>
                  </div>
                </div>
                { order.media && order.media.length > 0 &&
                <div className="flex-grow">
                  <FormLabel>Media</FormLabel>
                  <div className="flex justify-center mt-5">
                    <PreviewZoom thumbnail={true} urls={order.media} />
                  </div>
                </div>
                }
                <FormControl>
                  <Textarea
                    className="min-h-[100px]"
                    placeholder="Comentario del manager"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>)}
          />
          <FormField
            control={form.control}
            name="partners"
            render={() => (
              <FormItem>
                <div className="mb-4">
                  <FormLabel className="text-base">Asociados</FormLabel>
                </div>
                {partners.map((partner) => (
                  <FormField
                    key={partner.uuid}
                    control={form.control}
                    name="partners"
                    render={({ field }) => {
                      return (
                        <FormItem key={partner.uuid} className="flex flex-row items-start space-x-3 space-y-0">
                          <FormControl>
                            <Checkbox
                              checked={field.value?.includes(partner.uuid)}
                              onCheckedChange={(checked) => {
                                return checked
                                  ? field.onChange([...field.value, partner.uuid])
                                  : field.onChange(
                                field.value?.filter(
                                (v) => v !== partner.uuid
                              )
                                  )
                              }}
                            />
                          </FormControl>
                          <FormLabel className="font-normal">
                            {partner.first_name} {partner.last_name} ({partner.fantasy_name})
                          </FormLabel>
                        </FormItem>
                      )
                    }}
                  />
                ))}
                <FormMessage/>
              </FormItem>
            )}/>
          <FormField
            control={form.control}
            name="categories"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Categorías</FormLabel>
                <GridSelect
                  className="gap-1 grid-cols-2 sm:grid-cols-6"
                  slots={cats}
                  setSlots={
                    (val) => val.length == 0 ? form.setValue("categories", undefined) : form.setValue("categories", val)
                  }
                  options={categories_options}
                />
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="manpower"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Valor mano de obra</FormLabel>
                <FormControl>
                  <Input placeholder="$ Sin I.V.A." type="number" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormItem>
            <FormLabel>Materiales </FormLabel>
            <MaterialsTable materials={materials} setMaterials={setMaterials}/>
          </FormItem>
          <MaterialsDialog materials={materials} setMaterials={setMaterials}/>
          <FormField
            control={form.control}
            name="demands"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Requerimientos</FormLabel>
                <FormControl>
                  <Input placeholder="Despejar la zona, cortar agua, ..." {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <LoadingButton type="submit" text="Crear draft" loading={loading}/>
          </form>
          </Form>
          </div>
  )
}

function MaterialsTable({materials, setMaterials}: {materials: Materials, setMaterials: Dispatch<Materials>}) {
  function removeMaterial(idx: number) {
    let updated_total = materials.total - materials.mats[idx].total;
    let updated_list = [...materials.mats];
    updated_list.splice(idx, 1);
    let updated: Materials = {
      mats: updated_list,
      total: updated_total
    }
    setMaterials(updated);
  }
  if (!materials || materials.mats.length == 0) {
    return (<div></div>)
  } else {
    return (
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead></TableHead>
            <TableHead className="w-[100px]">Material</TableHead>
            <TableHead className="text-right">Cantidad</TableHead>
            <TableHead className="text-right">Precio referencial</TableHead>
            <TableHead className="text-right">Valor</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {
            materials.mats.map((mat, idx) => {
              return <TableRow>
                <TableCell><XIcon onClick={() => {removeMaterial(idx)}}/></TableCell>
                <TableCell className="font-medium">{mat.material.name}</TableCell>
                <TableCell className="text-right">{mat.quantity}</TableCell>
                <TableCell className="text-right">{formatClp(mat.unit_price)}</TableCell>
                <TableCell className="text-right">{formatClp(mat.total)}</TableCell>
              </TableRow>
          })}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell></TableCell>
            <TableCell colSpan={3}>Total neto</TableCell>
            <TableCell className="text-right">{formatClp(materials.total)}</TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    )
  }
}

function MaterialsDialog({materials, setMaterials}: {materials: Materials, setMaterials: Dispatch<Materials>}) {
  const materialFormSchema = z.object({
    material_name: z.string({
      required_error: "Material",
    }),
    unit_price: z.number({
      required_error: "Precio unitario"
    }).positive(),
    quantity: z.number({
      required_error: "Cantidad",
    }).positive(),
  })

  const [open, setOpen] = useState(false);

  const materialNameRef = useRef(null);
  const materialPriceRef = useRef(null);
  const materialQuantityRef = useRef(null);

  function addMaterial(e: React.FormEvent) {
    e.preventDefault();
    if (
      materialNameRef.current != null &&
      materialQuantityRef.current != null &&
      materialPriceRef.current != null
    ) {
      let mat: Material = {
        "material": { "name": materialNameRef.current["value"] },
        "unit_price": materialPriceRef.current["value"],
        "quantity": materialQuantityRef.current["value"],
        "total": materialQuantityRef.current["value"]* materialPriceRef.current["value"]
      };
      setMaterials({
        mats: [... materials.mats, mat],
        total: materials.total + mat.total
      });
    }
    setOpen(false);
  }

  let materialForm = useForm<z.infer<typeof materialFormSchema>>({
    resolver: zodResolver(materialFormSchema),
  })

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="outline">Agregar materiales</Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Agregar materiales</DialogTitle>
        </DialogHeader>
        <Form { ... materialForm}>
          <form className="grid gap-4 py-4" >
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="material_name" className="text-right">
                Material
              </Label>
              <Input
                id="material_name"
                className="col-span-3"
                ref={materialNameRef}
                required
              />
            </div>
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="unit_price" className="text-right">
                Precio unitario
              </Label>
              <Input
                id="unit_price"
                className="col-span-3"
                placeholder="$ Sin I.V.A."
                type="number"
                ref={materialPriceRef}
                required
              />
            </div>
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="quantity" className="text-right">
                Cantidad
              </Label>
              <Input
                id="quantity"
                type="number"
                className="col-span-3"
                ref={materialQuantityRef}
                required
              />
            </div>
            <Button onClick={addMaterial}>Save changes</Button>
          </form>
        </Form>
        <DialogFooter>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

const acceptOrderFormSchema = z.object({
  manager_comment: z.string({
    required_error: "Comentario",
  }),
  partners: z.array(z.string()).refine((value) => value.some((slot) => slot), {
    message: "Selecciona al menos un asociado",
  }),
  categories: z.array(z.string()).optional(),
  manpower: z.string({
    required_error: "Ingresa el valor de mano de obra",
  }).refine((val) => !Number.isNaN(parseInt(val, 10)), {
    message: "Ingresa un valor numérico"
  }),
  demands: z.string().optional(),
})

export default function OrderAccept() {
  const { state } = useLocation();
  let order = state.order;

  const [partners, setPartners] = useState<Array<Partner>>();
  const [loading, setLoading] = useState(false);
  const [materials, setMaterials] = useState<Materials>({mats: [], total: 0});
  const [hasAccess, setHasAccess] = useState<boolean>();

  const navigate = useNavigate();

  useEffect(() => {
    const fetchPartners = async () => {
      await fetch(`${HANDIFY_API_ENDPOINT}/api/people/partners`, {
        method: 'GET',
        credentials: 'include'
      }).then((response) => {
        if (response.status == 200) {
          response.json().then(data => {
            setPartners(data["results"]);
          })
        } else if (response.status == 403) {
          setHasAccess(false);
          setPartners([]);
        }
      })
    }

    fetchPartners();
  }, [])

  const goToTicket = (ticket: Ticket) => {
    setLoading(true);
    navigate('/tickets', { state: { "ticket": ticket } });
  }

  async function acceptOrderOnSubmit(values: z.infer<typeof acceptOrderFormSchema>) {
    if (values.categories == undefined || values.categories.length == 0) {
      return toastErr("Selecciona al menos una categoría");
    }
    let data = {
      "manager_comment": values.manager_comment,
      "categories": values.categories,
      "plan": {
        "candidates": values.partners,
        "requirements": {
          "manpower": values.manpower,
          "demands": values.demands,
          "materials": materials.mats.map((mat) => {
            return {
              "material": {
                "name": mat.material.name
              },
              "quantity": mat.quantity,
              "unit_price": mat.unit_price,
            }
          }),
        },
      }
    }
    setLoading(true);
    await fetch(`${HANDIFY_API_ENDPOINT}/api/tickets/orders/${order.uuid}/accept`, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify(data),
      headers: {
        'X-CSRFToken': getCsrfTokenFromCookie(),
        'Content-type': 'application/json; charset=UTF-8',
      },
    }).then((response) => {
      setLoading(false);
      response.json().then(data => {
        if (response.ok) {
          let t: Ticket = data;
          goToTicket(t);
        } else {
          toastErr(null, JSON.stringify(data));
        }
      }).catch((err) => {
        toastErr(null, err.message);
      });
    }).catch((err) => {
      toastErr(null, err.message);
    });
  }

  if (hasAccess == false) {
    return <Forbidden />;
  } else {
    return (
      <div className="p-2">
        { (order && partners) ?
          <>
            <Title child={<>Aceptar orden</>}/>
            <div className="flex justify-center">
              <div className="max-w-[900px]">
                <AcceptOrderForm loading={loading} order={order} partners={partners} onSubmit={acceptOrderOnSubmit} materials={materials} setMaterials={setMaterials}/>
              </div>
            </div>
          </>
          :
          <Loading />
        }
      </div>
    );
  }
}
