import { Customer } from "@/types"

export function CustomerDetails({customer} : {customer: Customer}) {
  return (
    <div className="grid grid-cols-1">
      <div>{customer.first_name} {customer.last_name}</div>
      <div>{customer.phone}</div>
      <div>{customer.orders.length} pedidos</div>
    </div>
  )
}

