import { useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

import {
  ColumnDef, ColumnFiltersState, SortingState, VisibilityState, flexRender,
  getCoreRowModel, getFilteredRowModel, getPaginationRowModel, getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table"
import {
  ArrowUpDown, ChevronDown, MoreHorizontal, Clock as ClockIcon,
} from "lucide-react"
import { Button } from "@/components/ui/button"
import { Checkbox } from "@/components/ui/checkbox"
import {
  DropdownMenu, DropdownMenuCheckboxItem, DropdownMenuContent,
  DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { Input } from "@/components/ui/input"
import {
  Table, TableBody, TableCell, TableHead, TableHeader, TableRow,
} from "@/components/ui/table"

import { HANDIFY_API_ENDPOINT } from "@/definitions"
import { District, Ticket, TICKET_ACCESS, TicketStatus, TICKET_STATUS } from "@/types"
import { CategoryBadges } from "@/components/categories"
import LoadingButton from "@/components/loading-button"
import { StatusIcon } from "@/components/ticket"
import { Loading } from "@/pages/loading"
import { Forbidden } from "@/pages/forbidden"
import { Title } from "@/components/title"
import { formatTimestamp, toastErr, userContext } from "@/utils"

type TicketRow = Ticket & {
  district: District,
  category_badges: JSX.Element,
  partner: string,
  email: string,
};

const COLUMNS: Array<ColumnDef<Ticket> & { access: string }> = [
  {
    access: TICKET_ACCESS.PARTNER,
    accessorKey: "status",
    header: "Status",
    cell: ({ row }) => (
      <div className="flex w-[120px] items-center gap-2">
        <StatusIcon status={row.getValue("status")} className="flex-none" size={20}/>
        <div className="font-semibold">{row.getValue("status")}</div>
      </div>
    ),
  },
  {
    access: TICKET_ACCESS.PARTNER,
    accessorKey: "district",
    header: "Comuna",
    cell: ({ row }) => (
      <div className="capitalize">{row.getValue("district")}</div>
    ),
  },
  {
    access: TICKET_ACCESS.PARTNER,
    accessorKey: "category_badges",
    header: "Categorías",
    cell: ({ row }) => ( <div className="w-[180px]">{row.getValue("category_badges")}</div>),
  },
  {
    access: TICKET_ACCESS.STAFF,
    accessorKey: "partner",
    header: "Asociado",
    cell: ({ row }) => (
      <div className="capitalize">{row.getValue("partner")}</div>
    ),
  },
  {
    access: TICKET_ACCESS.PARTNER,
    accessorKey: "short_uuid",
    header: "Referencia",
    cell: ({ row }) => ( <div className="font-mono">{row.getValue("short_uuid")}</div>),
  },
  {
    access: TICKET_ACCESS.STAFF,
    accessorKey: "email",
    header: "Email",
    cell: ({ row }) => <div className="lowercase">{row.getValue("email")}</div>,
  }
  // {
  //   id: "actions",
  //   enableHiding: false,
  //   cell: ({ row }) => {
  //     const router = useRouter()
  //     const goToTicket = () => {router.push(`/tickets/${row.original.uuid}`)}

  //     return (
  //       <DropdownMenu>
  //         <DropdownMenuTrigger asChild>
  //           <Button variant="ghost" className="h-8 w-8 p-0">
  //             <span className="sr-only">Open menu</span>
  //             <MoreHorizontal className="h-4 w-4" />
  //           </Button>
  //         </DropdownMenuTrigger>
  //         <DropdownMenuContent align="end">
  //           <DropdownMenuLabel>Acciones</DropdownMenuLabel>
  //           <DropdownMenuSeparator />
  //           <DropdownMenuItem onClick={goToTicket}>Ver ticket</DropdownMenuItem>
  //           <DropdownMenuItem disabled>Ver cliente</DropdownMenuItem>
  //         </DropdownMenuContent>
  //       </DropdownMenu>
  //     )
  //   },
  // },
]

interface MyDataTableProps {
  data:           Array<Ticket>;
  access:         string;
  nextPageUrl?:   string;
  getMoreTickets: (e: React.MouseEvent) => void;
  loading:        boolean;
}

function MyDataTable({data, access, nextPageUrl, getMoreTickets, loading}: MyDataTableProps) {
  const [sorting, setSorting] = useState<SortingState>([])
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({})
  const [rowSelection, setRowSelection] = useState({})

  let columns = COLUMNS.filter((column) => {
    return access == TICKET_ACCESS.STAFF || column.access == access;
  });

  const table = useReactTable({
    data,
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
    },
  })

  const navigate = useNavigate();
  const goToTicket = (ticket: Ticket) => {
    navigate('/tickets', { state: { "uuid": ticket.uuid }});
  }

  return (
    <div className="m-2 scrollable">
      <div className="flex items-center py-4">
        <Input
          placeholder="Buscar por referencia..."
          value={(table.getColumn("short_uuid")?.getFilterValue() as string) ?? ""}
          onChange={(event) =>
            table.getColumn("short_uuid")?.setFilterValue(event.target.value)
          }
          className="max-w-sm"
        />
      </div>
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    </TableHead>
                  )
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  onClick={() => goToTicket(row.original)}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  No hay tickets.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <div className="flex items-center justify-end space-x-2 py-4">
        <div className="space-x-2">
          <Button
            variant="outline"
            size="sm"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            Previous
          </Button>
          <Button
            variant="outline"
            size="sm"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            Next
          </Button>
        </div>
      </div>
      {
        nextPageUrl &&
          <div className="flex flex-wrap justify-center p-5">
            <LoadingButton className="" onClick={getMoreTickets} loading={loading} text="Ver más pedidos"/>
          </div>
      }
    </div>
  )
}

export function TicketsTable() {
  const {user, setUser} = useContext(userContext);
  let userAccess = "public";
  if ("access" in user) {
    userAccess = user.access;
  }
  const [details, setDetails] = useState<Array<TicketRow> | null>(null);
  const [hasAccess, setHasAccess] = useState(false);
  const [nextPageUrl, setNextPageUrl] = useState("#");
  const [loading, setLoading] = useState(false);

  function getMoreTickets(e: React.MouseEvent) {
    e.preventDefault();
    setLoading(true);
    fetch(nextPageUrl, {
      method: 'GET',
      credentials: 'include'
    }).then((response) => {
      if (response.ok) {
        response.json().then(data => {
          let new_details = data.results.map((ticket: Ticket) => {
            let category_badges = <CategoryBadges categories={ticket["categories"]}/>;
            let t: TicketRow = {
              category_badges: category_badges,
              email: ticket.order.customer?.email || "-",
              district: ticket.order.district,
              partner: ticket.plan.proposal?.partner.fantasy_name || "-",
              ...ticket
            };
            return t;
          });
          if (!details) {
            setDetails(new_details);
          } else {
            setDetails([...details, ...new_details]);
          }
          setNextPageUrl(data["next"]);
        });
      } else {
        response.json().then(data => {
          toastErr("Algo salió mal...", JSON.stringify(data, null, 2))
        })
      }
      setLoading(false);
    }).catch((err) => {
      toastErr("algo no está bien", err.message);
    });
  }

  useEffect(() => {
    const fetchTickets = async () => {
      await fetch(`${HANDIFY_API_ENDPOINT}/api/tickets`, {
        method: 'GET',
        credentials: 'include'
      }).then((response) => {
        if (response.ok) {
          response.json().then(data => {
            let details = data.results.map((ticket: Ticket) => {
              let category_badges = <CategoryBadges categories={ticket["categories"]}/>;
              let t: TicketRow = {
                category_badges: category_badges,
                email: ticket.order.customer?.email || "-",
                district: ticket.order.district,
                partner: ticket.plan.proposal?.partner.fantasy_name || "-",
                ...ticket
              };
              return t;
            });
            setDetails(details);
            setNextPageUrl(data["next"]);
            setHasAccess(true);
          });
        } else {
          setHasAccess(false);
          setDetails([]);
        }
      }).catch((err) => {
        toastErr(null, err.message);
      });
    };
    fetchTickets()
  }, []);

  if (details == null) {
    return <Loading />
  }

  if (hasAccess == false) {
    return <Forbidden />
  }

  return (
    <div>
      <Title child={<>Tickets</>}/>
      <div className="sm:flex sm:justify-center">
        <div className="sm:w-[800px]">
          <MyDataTable
            data={details}
            access={userAccess}
            nextPageUrl={nextPageUrl}
            getMoreTickets={getMoreTickets}
            loading={loading}
          />
        </div>
      </div>
    </div>
  )
}
