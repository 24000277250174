// 3rd party imports
import { useState } from "react"
import "react-day-picker/dist/style.css";
import { useNavigate } from "react-router-dom"

// UI imports
import { Button } from "@/components/ui/button"
import { Pencil as PencilIcon, Send as SendIcon, TriangleAlert as WarningIcon } from "lucide-react"
import {
  Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader,
  DialogTitle, DialogTrigger,
} from "@/components/ui/dialog"

// App imports
import { Ticket, TicketStatus, TICKET_STATUS } from "@/types"
import { HANDIFY_API_ENDPOINT } from "@/definitions"
import {
  GetInvoiceLink, StatusDescription, TicketLog, TicketDetails
} from "@/components/ticket"
import { getCsrfTokenFromCookie, toastOk, toastErr } from "@/utils"
import { Title } from "@/components/title"
import { PartnerDetails } from "@/components/partner"
import LoadingButton from "@/components/loading-button"

function TicketDialogs({ticket}: {ticket: Ticket}) {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const goToEditTicket = (e: React.MouseEvent) => {
    e.preventDefault();
    setLoading(true);
    navigate('/tickets/update', { state: { "ticket": ticket }});
  };
  let dialogs = <></>;
  switch (ticket.status) {
    case TICKET_STATUS.DRAFT:
      dialogs = <>
        <LoadingButton icon={<PencilIcon />} variant="outline" onClick={goToEditTicket} text="Editar" loading={loading}/>
        <SendTicketDialog uuid={ticket.uuid}/>
      </>;
      break;
    case TICKET_STATUS.RETRACTED:
      dialogs = <>
        <LoadingButton icon={<PencilIcon />} variant="outline" onClick={goToEditTicket} text="Editar" loading={loading}/>
        <SendTicketDialog uuid={ticket.uuid}/>
      </>;
      break;
    case TICKET_STATUS.OPEN:
      dialogs = <>
        <RetractTicketDialog className="w-full" status={ticket.status} uuid={ticket.uuid}/>
      </>;
      break;
    case TICKET_STATUS.STANDBY:
      dialogs = <>
        <RetractTicketDialog status={ticket.status} uuid={ticket.uuid}/>
      </>;
      break;
  };
  return dialogs;
}

function SendTicketDialog({uuid}: {uuid: string}) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  async function sendTicket(e: React.MouseEvent) {
    e.preventDefault();
    setLoading(true);
    await fetch(`${HANDIFY_API_ENDPOINT}/api/tickets/${uuid}/plan`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'X-CSRFToken': getCsrfTokenFromCookie(),
        'Content-type': 'application/json; charset=UTF-8',
      },
    }).then((response) => {
      setLoading(false);
      if (response.ok) {
        window.location.reload();
        toastOk(`El ticket ${uuid} ha sido enviado a los asociados`);
      } else {
        response.json().then(data => {
          toastErr(null, JSON.stringify(data, null, 2));
        });
      }
    }).catch((err) => {
      toastErr("El servidor dice", err.message);
    });
    setOpen(false);
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button>Enviar a los asociados<SendIcon /></Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Enviar ticket</DialogTitle>
        </DialogHeader>
        <DialogDescription>
          Cuando un asociado acepte este plan, se enviará automáticamente la propuesta al cliente.
        </DialogDescription>
        <DialogFooter>
          <LoadingButton onClick={sendTicket} text="Entiendo, enviar" loading={loading}/>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

function RetractTicketDialog({className, status, uuid}: {className?: string, status: TicketStatus, uuid: string}) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  async function retractTicket(e: React.MouseEvent) {
    e.preventDefault();
    setLoading(true);
    await fetch(`${HANDIFY_API_ENDPOINT}/api/tickets/${uuid}/retract`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'X-CSRFToken': getCsrfTokenFromCookie(),
        'Content-type': 'application/json; charset=UTF-8',
      },
    }).then((response) => {
      setLoading(false);
      if (response.ok) {
        window.location.reload();
        toastOk("El ticket " + uuid + " ha sido retractado.");
      } else {
        response.json().then(data => {
          toastErr(null, JSON.stringify(data, null, 2));
        });
      }
    }).catch((err) => {
      toastErr("El servidor dice", err.message);
    });
    setOpen(false);
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="destructive"><WarningIcon />Retractar</Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Retractar</DialogTitle>
        </DialogHeader>
        {
          status === TICKET_STATUS.OPEN &&
            <DialogDescription>
              Atención: este ticket ya fue enviado a los asociados y aún no ha sido
              aceptado. Una vez retractado, los asociados no podrán ver el ticket.
            </DialogDescription>
        }
        {
          status === TICKET_STATUS.ASSIGNED &&
            <DialogDescription>
              Atención: este ticket ya fue aceptado por un asociado. Una vez retractado, el asociado no podrá ver el ticket.
            </DialogDescription>
        }
        {
          status === TICKET_STATUS.STANDBY &&
            <DialogDescription>
              Atención: este ticket ya fue enviado al cliente y aún no ha sido
              pagado. Una vez retractado, el cliente no podrá acceder a la propuesta.
            </DialogDescription>
        }
        <DialogFooter>
          <LoadingButton onClick={retractTicket} text="Entiendo, retractar" loading={loading}/>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

// TODO
// function ReopenTicketDialog({uuid}: {uuid: Uuid}) {
//   const [open, setOpen] = useState(false);
//   const [loading, setLoading] = useState(false);

//   const reopenFormSchema = z.object({
//     reason: z.string({
//       required_error: "Comentario de reapertura es obligatorio",
//     }),
//   })

//   const form = useForm<z.infer<typeof reopenFormSchema>>({
//     resolver: zodResolver(reopenFormSchema)
//   });

//   async function reopenTicket(values: z.infer<typeof reopenFormSchema>) {
//     toastErr("Esto aún no está implementado", null);
//   }

//   return (
//     <Dialog open={open} onOpenChange={setOpen}>
//       <DialogTrigger asChild>
//         <Button variant="secondary" className="w-full">
//           Reabrir ticket
//         </Button>
//       </DialogTrigger>
//       <DialogContent className="sm:max-w-[425px]">
//         <DialogHeader>
//           <DialogTitle>Reabrir ticket</DialogTitle>
//         </DialogHeader>
//         <DialogDescription>
//           El ticket será reabierto y el cliente recibirá un email.
//         </DialogDescription>
//         <Form { ... form }>
//           <form onSubmit={form.handleSubmit(reopenTicket)}>
//             <FormField
//               control={form.control}
//               name="reason"
//               render={({ field }) => (
//               <FormItem>
//                 <FormLabel>
//                   Razón de reapertura
//                 </FormLabel>
//                 <FormControl>
//                   <Textarea
//                     {... field}
//                   />
//                 </FormControl>
//                 <FormMessage />
//               </FormItem>)}
//               />
//             <FormItem className="pt-4 flex justify-end">
//               <LoadingButton type="submit" text="Reabrir ticket" loading={loading}/>
//             </FormItem>
//             </form>
//           </Form>
//         </DialogContent>
//       </Dialog>
//   )
// }

export function TicketStaff({ticket}: {ticket: Ticket}) {
  let dialogs = <TicketDialogs ticket={ticket} />;
  return (
    <div>
      <Title child={<>Detalle ticket</>}/>
      <div className="flex flex-wrap justify-center gap-7">
        <div className="flex-shrink p-3 max-w-[650px] overflow-x-auto">
          { ticket.status &&
          <>
            <h2>
              Estado: {ticket.status}
            </h2>
            <p>
              <StatusDescription status={ticket.status} />
            </p>
            <h2 className="pt-3">Asociado</h2>
            {
              ticket.plan && ticket.plan.proposal && ticket.plan.proposal.partner ?
                <PartnerDetails partner={ticket.plan.proposal.partner} />
                :
                <p>Este ticket no ha sido asignado.</p>
            }
            <h2 className="pt-3">Pago</h2>
            <p>Este ticket { (ticket.is_payed) ? " ": " no " } ha sido pagado por el usuario. </p>
            { ticket.token &&
            <>
              <p>
                <a className="hyperlink" href={`/tickets?token=${ticket.token}`}>
                  { ticket.status === TICKET_STATUS.STANDBY ? "Link de pago" : "Vista del usuario" }
                </a>
              </p>
            </>
            }
            {
              ticket.token && ticket.is_payed && <GetInvoiceLink token={ticket.token} />
            }
          </>
          }
          { ticket.ticket_log_entries &&
            <>
              <h2 className="pt-3">Histórico</h2>
              <div>
                <TicketLog logs={ticket.ticket_log_entries} />
              </div>
            </>
          }
        </div>
        <div>
          <TicketDetails ticket={ticket} dialogs={dialogs}/>
        </div>
      </div>
    </div>
  )
}

