import { TriangleAlert as WarningIcon } from "lucide-react"
import { Alert, AlertDescription, AlertTitle, } from "@/components/ui/alert"
import { CardTitle, } from "@/components/ui/card"

import { Address, Proposal } from "@/types"
import { countDown, formatTimestamp, formatClp, toastErr } from "@/utils"
import { HOUSING_TYPE } from "@/definitions"

export function ProposalDetails({proposal, address, expired}: {proposal: Proposal, address: Address | null, expired: boolean}) {
  return (
    <>
      <h2 className="text-center font-bold">
        Primera visita
      </h2>
      <h3 className="text-center mb-5 font-semibold">
        {formatTimestamp(proposal.first_visit, true)}
      </h3>
      { !expired && proposal.expires_at &&
      <Alert className="mb-4 text-center">
        <AlertDescription className="text-lg font-bold">Tiempo restante para confirmar: {countDown(proposal.expires_at)}</AlertDescription>
      </Alert>
      }
      { expired && proposal.expires_at &&
      <Alert className="mb-4 text-center">
        <AlertDescription className="text-lg font-bold">Expirado el {formatTimestamp(proposal.expires_at)}</AlertDescription>
      </Alert>
      }
      { address &&
        <>
          <CardTitle className="lone">Dirección</CardTitle>
          <div>{address.line_1}</div>
          <div>{address.line_2}</div>
          <div>Piso: {address.floor}</div>
          <div>Tipo: {HOUSING_TYPE[address.housing]}</div>
          <div>{address.parking? <>Estacionamiento disponible</> : <div className="flex justify-items-begin gap-1"><WarningIcon /><span className="inline font-semibold">No hay estacionamiento</span> </div>}</div>
        </>
      }
    </>
  )
}
