import { useEffect, useState } from "react";

import { fetchUser, userContext } from "@/utils"
import { User } from "@/types"
import { BrowserRouter } from 'react-router-dom';

import { Toaster } from "./components/ui/toaster";

import Header from '@/components/header';
import Footer from '@/components/footer';
import AppRoutes from '@/app-routes';

function App() {

  const [user, setUser] = useState<User>({"user": "anonymous"});
  useEffect(() => {
    fetchUser(setUser);
  }, []);

  return (
    <BrowserRouter>
      <userContext.Provider value={{ user: user, setUser: setUser }}>
        <div className={`max-w-screen font-[sans] font-[mono] flex flex-col min-h-screen justify-between`}>
          <Header />
          <AppRoutes className="mb-5 sm:mb-10"/>
          <Toaster/>
          <Footer/>
        </div>
      </userContext.Provider>
    </BrowserRouter>
  );
}

export default App;
