// 3rd party imports
import { useEffect, useState } from "react"

// UI imports
import { CardContent, CardTitle, } from "@/components/ui/card"
import {
  Accordion, AccordionContent, AccordionItem, AccordionTrigger,
} from "@/components/ui/accordion"

// App imports
import { Partner, Plan, Uuid } from "@/types"
import { HANDIFY_API_ENDPOINT } from "@/definitions"
import { toastErr } from "@/utils"
import { PartnerDetails } from "@/components/partner"
import { PriceTables } from "@/components/requirement"

function PartnerNames({uuids}: {uuids: Array<Uuid>}) {
  const [partners, setPartners] = useState<Array<Partner>>();

  useEffect(() => {
    const fetchPartners = async () => {
      await fetch(`${HANDIFY_API_ENDPOINT}/api/people/partners/`, {
        method: 'GET',
        credentials: 'include'
      }).then((response) => {
        response.json().then(data => {
          let filtered = data["results"].filter((partner: Partner) => {
            return uuids.includes(partner.uuid);
          });
          setPartners(filtered);
        });
      }).catch((err) => {
        toastErr(null, err.message);
      });
    };

    fetchPartners()
  }, []);

  return (
    <div>
      { partners ? partners.map((p) => { return <PartnerDetails partner={p} /> }) : "Cargando..." }
    </div>
  )
}

export function PlanDetails({plan}: {plan: Plan}) {
  return (
    <div>
      { plan.proposal && plan.proposal.partner &&
      <CardContent>
        <CardTitle className="lone">Asociado</CardTitle>
        <PartnerDetails partner={plan.proposal.partner} />
      </CardContent>
      }
      { plan.candidates && plan.candidates.length > 0 &&
        <CardContent>
          <Accordion type="single" collapsible>
            <AccordionItem value="candidates" className="border-none">
              <AccordionTrigger>Ver candidatos</AccordionTrigger>
              <AccordionContent>
                <PartnerNames uuids={plan.candidates} />
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        </CardContent>
      }
      { plan.requirements && plan.requirements.demands &&
          <CardContent>
            <CardTitle className="lone">Requerimientos</CardTitle>
            <div>{plan.requirements.demands}</div>
          </CardContent>
      }
      {
        (plan.requirements || plan.requirements_raw) &&
          <CardContent>
            <CardTitle className="lone">Presupuesto</CardTitle>
            <PriceTables plan={plan} />
          </CardContent>
      }
    </div>
  )
}
