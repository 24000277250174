import { Loader2 } from "lucide-react"

export function Loading() {
  return (
    <div className="grid justify-items-center items-center min-h-[400px] font-[family-name:var(--font-geist-sans)]">
      <div className="flex flex-col gap-4 row-start-2 items-center">
        <Loader2 className="animate-spin" size={48} />
        <h2 className="text-center">Cargando...</h2>
      </div>
    </div>
  );
}
