import { Dispatch } from "react"
import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"

import LoadingButton from "@/components/loading-button"
import { Input } from "@/components/ui/input"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form"

const loginFormSchema = z.object({
  email: z
  .string({
    required_error: "Ingrese su email"
  }).email({ message: "Email inválido" }),
  password: z
  .string({ required_error: "" }).max(50),
})

export interface LoginFormFields {
  email:     string;
  password:  string;
  username?: string;
}

interface LoginFormProps {
  onSubmit: (f: LoginFormFields) => void;
  loading: boolean;
  setLoading: Dispatch<boolean>
}

export function LoginForm({onSubmit, loading, setLoading}: LoginFormProps) {
  const form = useForm<z.infer<typeof loginFormSchema>>({
    resolver: zodResolver(loginFormSchema),
  })

  function wrappedOnSubmit(f: LoginFormFields) {
    setLoading(true);
    onSubmit(f);
  }

  return (
    <Form {...form}>
        <form onSubmit={form.handleSubmit(wrappedOnSubmit)} className="space-y-8">
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
          <FormItem>
            <FormLabel>Correo electrónico</FormLabel>
            <FormControl>
              <Input placeholder="Email" {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
          )}
          />
        <FormField
          control={form.control}
          name="password"
          render={({ field }) => (
          <FormItem>
            <FormLabel>Contraseña</FormLabel>
            <FormControl>
              <Input placeholder="••••••••" type="password" {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
          )}
          />
        <LoadingButton type="submit" text="Entrar" className="w-full" loading={loading} />
        </form>
        </Form>
  )
}
