import { Loader2 } from "lucide-react"
import { Button } from "@/components/ui/button"

interface LoadingButtonProps {
  text:       string;
  type?:      string;
  variant?:   "default" | "destructive" | "outline" | "secondary" | "ghost" | "link" | null | undefined;
  loading:    boolean;
  icon?:      React.ReactElement;
  className?: string;
  onClick?:   React.MouseEventHandler;
}

  export default function LoadingButton({text, type, variant, loading, icon, className, onClick}: LoadingButtonProps) {
  return (
    <Button variant={variant} className={`place-self-center ${className}`} type="submit" onClick={onClick}>
      {
        loading ? <><Loader2 className="animate-spin" /> Cargando</> : icon ? <>{text} {icon}</>: <>{text}</>
      }
    </Button>
  );
}
