import { Navigate, Routes, Route } from 'react-router-dom';

import Backlog from '@/pages/orders'
import Book from '@/pages/book'
import Landing from '@/pages/landing'
import Login from '@/pages/login'
import OrderAccept from '@/pages/order-accept'
import TicketUpdate from '@/pages/ticket-update'
import TicketView from '@/pages/tickets'
import TransactionResult from '@/pages/transaction'

type Props = {
  className?: string
}

function AppRoutes(props: Props) {
  return (
    <div className={props.className}>
      <div>
        <Routes>
          <Route path='/' element={<Landing />} />
          <Route path='/login' element={<Login />} />
          <Route path='/book' element={<Book />} />
          <Route path='/orders' element={<Backlog />} />
          <Route path='/orders/accept' element={<OrderAccept />} />
          <Route path='/tickets' element={<TicketView />} />
          <Route path='/tickets/:uuid' element={<TicketView />} />
          <Route path='/tickets/update' element={<TicketUpdate />} />
          <Route path='/tickets/all' element={<Navigate to="/tickets" />} />
          <Route path='/tickets/:uuid/transaction' element={<TransactionResult />} />
          <Route path='*' element={<Navigate to="/" replace />} />
        </Routes>
      </div>
    </div>
  );
}

export default AppRoutes;
