import HandifyLogoSimple from '@/components/logo/handify'

export function Forbidden() {
  return (
    <div className="grid justify-items-center items-center min-h-[400px] font-[family-name:var(--font-geist-sans)]">
      <div className="flex flex-col gap-8 row-start-2 justify-items-center items-center">
        <HandifyLogoSimple className="max-w-40 mx-auto h-auto"/>
        <h2 className="max-w-[350px] text-center">No tienes permisos para ver esta página</h2>
      </div>
    </div>
  );
}
