import { Link } from 'react-router-dom'
import { SocialIcon } from 'react-social-icons'

import {INSTAGRAM_URL, WHATSAPP_URL, CONTACT_EMAIL} from '@/definitions'

import HandifyLogoSimple from '@/components/logo/handify'

export default function Footer() {
  return (
    <footer>
      <div className="bg-blue-300 py-4 md:py-7">
        <div className="max-w-6xl mx-auto px-4 sm:px-6">

          {/* Top area: Blocks */}
          <div className="grid md:grid-cols-12 gap-8 lg:gap-20 mb-3">

            {/* 1st block */}
            <div className="md:col-span-4 lg:col-span-5">
              <div className="mb-2 max-w-[64px]">
                {/* Logo */}
                <Link to="/" className="inline-block">
                  <HandifyLogoSimple />
                </Link>
              </div>
              <div className="text-gray-400">Handify te ayuda en tus proyectos con profesionales certificados, transparencia, y seguridad.</div>
            </div>
            <div className="md:col-span-4 lg:col-span-3">
            </div>

            {/* 2nd, 3rd blocks */}
            <div className="md:col-span-8 lg:col-span-4 grid sm:grid-cols-2 gap-8">

              {/* 4th block */}
              <div className="text-sm align-middle">
                <div className="align-middle">
                  <h6 className="text-gray-200 font-medium mb-1">Contacto</h6>
                  <ul>
                    <li className="mb-1">
                      <Link to={"mailto:" + CONTACT_EMAIL} className="text-gray-400 hover:text-gray-100 transition duration-150 ease-in-out">{CONTACT_EMAIL}</Link>
                    </li>
                    <li className="mb-1">
                      <Link to={WHATSAPP_URL} className="text-gray-400 hover:text-gray-100 transition duration-150 ease-in-out">Whatsapp</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          {/* Bottom area */}
          <div className="flex items-center justify-between">

            {/* Copyrights note */}
            <div className="text-gray-400 text-sm mr-4">&copy; 2024 Handify.cl</div>

            {/* Social links */}
            <div className="flex gap-3 md:ml-4 md:mb-0 justify-end">
              <Link to={WHATSAPP_URL}><SocialIcon network="whatsapp" style={{ height: 40, width: 40 }}/>  </Link>
              <Link to={INSTAGRAM_URL}><SocialIcon network="instagram" style={{ height: 40, width: 40 }}/>  </Link>
            </div>

          </div>
        </div>
      </div>
    </footer>
  )
}
