// 3rd party imports
import { Dispatch, useEffect, useState, useRef } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm, SubmitHandler } from "react-hook-form"

// UI imports
import { X as XIcon } from "lucide-react"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { Button } from "@/components/ui/button"
import {
  Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage
} from "@/components/ui/form"
import {
  Table, TableBody, TableCell, TableFooter, TableHead, TableHeader,
  TableRow,
} from "@/components/ui/table"
import {
  Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle, DialogTrigger,
} from "@/components/ui/dialog"
import { Textarea } from "@/components/ui/textarea"
import { Checkbox } from "@/components/ui/checkbox"

// App imports
import { CATEGORIES, HANDIFY_API_ENDPOINT } from "@/definitions"
import { CustomerDetails } from "@/components/order"
import { GridSelect } from "@/components/grid-select"
import { PreviewZoom } from "@/components/preview-zoom"
import { Loading } from "@/pages/loading"
import { Title } from "@/components/title"
import { formatClp, getCsrfTokenFromCookie, toastErr } from "@/utils"
import LoadingButton from "@/components/loading-button"
import { Partner, Ticket, Uuid } from "@/types"

interface UpdateTicketFormProps {
  allPartners:  Array<Partner>;
  ticket:       Ticket;
  materials:    Materials;
  setMaterials: Dispatch<Materials>;
  onSubmit:     SubmitHandler<z.infer<typeof updateTicketFormSchema>>;
  loading:      boolean;
}

function UpdateTicketForm({
  allPartners, ticket, materials, setMaterials, onSubmit, loading
}: UpdateTicketFormProps) {
  const form = useForm<z.infer<typeof updateTicketFormSchema>>({
    resolver: zodResolver(updateTicketFormSchema),
    defaultValues: {
      candidates: ticket.plan.candidates,
      categories: ticket.categories,
      manager_comment: ticket.manager_comment,
      manpower: ticket.plan.requirements_raw.manpower,
    },
  })
  let categories_options: {[id:string]: string} = {}
  Object.entries(CATEGORIES).map(([key, value]) => categories_options[key] = value['label'] );

  return (
    <Form { ... form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
        <h2>Comuna: <>{ticket.order.district}</></h2>
        <p><CustomerDetails customer_details={ticket.order.customer}/></p>
        <FormDescription className="max-w-[600px]">Descripción trabajos: {ticket.order.description}</FormDescription>
        { ticket.order.media &&
        <div className="flex-grow">
          <FormLabel>Media</FormLabel>
          <div className="flex justify-center mt-5">
            <PreviewZoom thumbnail={true} urls={ticket.order.media} />
          </div>
        </div>
        }
        <FormField
          control={form.control}
          name="manager_comment"
          render={({ field }) => (
          <FormItem>
            <FormLabel>Comentarios</FormLabel>
            <FormControl>
              <Textarea
                placeholder="Comentario del manager"
                {...field}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
          )}/>
        <FormField
          control={form.control}
          name="candidates"
          render={() => (
            <FormItem>
              <div className="mb-4">
                <FormLabel className="text-base">Asociados</FormLabel>
              </div>
              {allPartners.map((partner) => (
                <FormField
                  key={partner.uuid}
                  control={form.control}
                  name="candidates"
                  render={({ field }) => {
                    return (
                      <FormItem key={partner.uuid} className="flex flex-row items-start space-x-3 space-y-0">
                        <FormControl>
                          <Checkbox
                            checked={field.value?.includes(partner.uuid)}
                            onCheckedChange={(checked) => {
                              return checked
                                ? field.onChange([...field.value, partner.uuid])
                                : field.onChange(
                              field.value?.filter(
                              (v) => v !== partner.uuid
                            )
                                )
                            }}
                          />
                        </FormControl>
                        <FormLabel className="font-normal">
                          {partner.first_name} {partner.last_name} ({partner.fantasy_name})
                        </FormLabel>
                      </FormItem>
                    )
                  }}
                  />
              ))}
              <FormMessage/>
                </FormItem>
          )}/>
        <FormField
          control={form.control}
          name="categories"
          render={({ field }) => (
          <FormItem>
            <FormLabel>Categorías</FormLabel>
            <GridSelect
              className="gap-1 grid-cols-2 sm:grid-cols-6"
              slots={ticket.categories}
              setSlots={
                (val) => val.length === 0 ? form.setValue("categories", undefined) : form.setValue("categories", val)
              }
              options={categories_options}
            />
            <FormMessage />
          </FormItem>
          )}
          />
        <FormField
          control={form.control}
          name="manpower"
          render={({ field }) => (
          <FormItem>
            <FormLabel>Valor mano de obra</FormLabel>
            <FormControl>
              <Input placeholder="$" type="number" {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
          )}
          />
        <FormItem>
          <FormLabel>Materiales </FormLabel>
          <MaterialsTable materials={materials} setMaterials={setMaterials}/>
        </FormItem>
        <MaterialsDialog materials={materials} setMaterials={setMaterials}/>
        <FormField
          control={form.control}
          name="demands"
          render={({ field }) => (
          <FormItem>
            <FormLabel>Requerimientos</FormLabel>
            <FormControl>
              <Input placeholder="Despejar la zona, cortar agua, ..." {...field} defaultValue={ticket.plan.requirements.demands}/>
            </FormControl>
            <FormMessage />
          </FormItem>
          )}
          />
        <LoadingButton type="submit" text="Guardar" loading={loading} />
        </form>
        </Form>
  )
}

interface Material {
  name:     string;
  unit_price:    number;
  quantity: number;
  total:    number;
}

interface Materials {
  mats: Array<Material>;
  total: number
}

interface MaterialsStateProps {
  materials: Materials;
  setMaterials: Dispatch<Materials>;
}

function MaterialsTable({materials, setMaterials}: MaterialsStateProps) {
  if (!materials || materials.mats.length === 0) {
    return (<div></div>)
  } else {
    return (
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead></TableHead>
            <TableHead className="w-[100px]">Material</TableHead>
            <TableHead className="text-right">Cantidad</TableHead>
            <TableHead className="text-right">Precio referencial</TableHead>
            <TableHead className="text-right">Total neto</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {
            materials.mats.map((mat, idx) => {
              return <TableRow>
                <TableCell><XIcon onClick={(e) => {
                  let updated_total = materials.total - materials.mats[idx].total;
                  let updated_list = [...materials.mats];
                  updated_list.splice(idx, 1);
                  let updated: Materials = {
                    mats: updated_list,
                    total: updated_total
                  }
                  setMaterials(updated);
                  }}/></TableCell>
                <TableCell className="font-medium">{mat.name}</TableCell>
                <TableCell className="text-right">{mat.quantity}</TableCell>
                <TableCell className="text-right">{formatClp(mat.unit_price)}</TableCell>
                <TableCell className="text-right">{formatClp(mat.total)}</TableCell>
              </TableRow>
          })}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell></TableCell>
            <TableCell colSpan={3}>Total</TableCell>
            <TableCell className="text-right">{formatClp(materials.total)}</TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    )
  }
}

function MaterialsDialog({materials, setMaterials}: MaterialsStateProps) {
  const materialFormSchema = z.object({
    material_name: z.string({
      required_error: "Material",
    }),
    unit_price: z.number({
      required_error: "Precio unitario"
    }).positive(),
    quantity: z.number({
      required_error: "Cantidad",
    }).positive(),
  })

  const [open, setOpen] = useState(false);

  const materialNameRef = useRef(null);
  const materialPriceRef = useRef(null);
  const materialQuantityRef = useRef(null);

  function addMaterial(e: React.MouseEvent) {
    e.preventDefault();
    if (
      materialNameRef.current != null &&
      materialQuantityRef.current != null &&
      materialPriceRef.current != null
    ) {
      let mat: Material = {
        "name": materialNameRef.current["value"],
        "unit_price": materialPriceRef.current["value"],
        "quantity": materialQuantityRef.current["value"],
        "total": materialQuantityRef.current["value"] * materialPriceRef.current["value"]
      }
      setMaterials({
        mats: [...materials.mats, mat],
        total: materials.total + mat.total
      });
    }
    setOpen(false);
  }

  let materialForm = useForm<z.infer<typeof materialFormSchema>>({
    resolver: zodResolver(materialFormSchema),
  })

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="outline">Agregar materiales</Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Agregar materiales</DialogTitle>
        </DialogHeader>
        <Form { ... materialForm}>
          <form className="grid gap-4 py-4" >
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="material_name" className="text-right">
                Material
              </Label>
              <Input
                id="material_name"
                className="col-span-3"
                ref={materialNameRef}
                required
              />
            </div>
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="unit_price" className="text-right">
                Precio unitario
              </Label>
              <Input
                id="unit_price"
                className="col-span-3"
                placeholder="$ Sin I.V.A."
                type="number"
                ref={materialPriceRef}
                required
              />
            </div>
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="quantity" className="text-right">
                Cantidad
              </Label>
              <Input
                id="quantity"
                type="number"
                className="col-span-3"
                ref={materialQuantityRef}
                required
              />
            </div>
            <Button onClick={addMaterial}>Save changes</Button>
          </form>
        </Form>
        <DialogFooter>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

const updateTicketFormSchema = z.object({
  manager_comment: z.string({
    required_error: "Comentario",
  }),
  categories: z.array(z.string()).optional(),
  candidates: z.array(z.string()).refine((value) => value.some((slot) => slot), {
    message: "Selecciona al menos un asociado",
  }),
  manpower: z.coerce.number({
    required_error: "Ingresa el valor de mano de obra",
  }),
  demands: z.string().optional(),
})

export default function TicketUpdate() {
  const { state } = useLocation();

  const [ticket, setTicket] = useState<Ticket>();
  const [materials, setMaterials] = useState<Materials>({mats: [], total: 0});
  const [allPartners, setAllPartners] = useState<Array<Partner>>([]);
  const [fetched, setFetched] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  const fetchTicket = async (uuid: Uuid) => {
    setLoading(true);
    await fetch(`${HANDIFY_API_ENDPOINT}/api/tickets/${uuid}`, {
      method: 'GET',
      credentials: 'include'
    }).then((response) => {
      if (response.ok) {
        response.json().then(data => {
          let t: Ticket = { ...data };
          setTicket(t);
          setLoading(false);
        })
      } else {
        setLoading(false);
      }
    }).catch((err) => {
      toastErr("El servidor dice", err.message);
    });
  }

  if (state) {
    // state = { ticket: {...} } => OK
    // state = { uuid: {...}, ...} => fetch
    if (!ticket && "ticket" in state) {
      setTicket(state.ticket);
      state["uuid"] = state.ticket.uuid;
    }
    if (!fetched && !("ticket" in state) && "uuid" in state) {
      fetchTicket(state.uuid);
      setFetched(true);
    }
  }

  // Add stuff in materials
  useEffect(() => {
    if (ticket) {
      setMaterials({
        "mats": ticket["plan"]["requirements_raw"]["materials"].map(
          (mat) => {
            return {
              "name": mat["material"]["name"],
              "quantity": mat["quantity"],
              "unit_price": mat["unit_price"],
              "total": mat["total"],
            }
          }
        ),
        "total": ticket["plan"]["requirements_raw"]["materials_total"]
      });
    }
  }, [ticket]);

  useEffect(() => {
    const fetchPartners = async () => {
      await fetch(`${HANDIFY_API_ENDPOINT}/api/people/partners`, {
        method: 'GET',
        credentials: 'include'
      }).then((response) => {
        response.json().then(data => {
          setAllPartners(data["results"]);
        })
      })
    }
    fetchPartners();
  }, []);

  const navigate = useNavigate()
  const goToTicket = () => {
    setLoading(true);
    navigate('/tickets', { state: { "ticket": ticket }});
  }

  async function updateTicketOnSubmit(values: z.infer<typeof updateTicketFormSchema>) {
    let data = {
      "uuid": ticket?.uuid,
      "manager_comment": values.manager_comment,
      "categories": values.categories,
      "plan": {
        "requirements": {
          "manpower": values.manpower,
          "demands": values.demands,
          "materials": materials.mats.map((mat) => {
            return {
              "material": {
                "name": mat.name
              },
              "quantity": mat.quantity,
              "unit_price": mat.unit_price,
            }
          }),
        },
        "candidates": values.candidates,
      }
    }
    setLoading(true);

    await fetch(`${HANDIFY_API_ENDPOINT}/api/tickets/${ticket?.uuid}/`, {
      method: 'PATCH',
      credentials: 'include',
      body: JSON.stringify(data),
      headers: {
        'X-CSRFToken': getCsrfTokenFromCookie(),
        'Content-type': 'application/json; charset=UTF-8',
      },
    }).then((response) => {
      setLoading(false);
      if (!response.ok) {
        response.text().then(function(data) {
          toastErr(null, data);
        })
      } else {
        response.json().then(data => {
          goToTicket();
        });
      }
    }).catch((err) => {
        toastErr(null, err.message);
    });
  }

    return (
        <>
            { ticket ?
                <div>
                    <Title child={<>Actualizar ticket</>}/>
                    <div className="flex justify-center p-2">
                        <div>
                            {ticket ?
                                <UpdateTicketForm
                                    ticket={ticket}
                                    allPartners={allPartners}
                                    onSubmit={updateTicketOnSubmit}
                                    loading={loading}
                                    materials={materials} setMaterials={setMaterials}/>
                                    :
                                    <div>Cargando...</div>
                            }
                        </div>
                    </div>
                </div>
                :
                <Loading />
            }
        </>
    );
}
