import * as React from "react"
import { useEffect, useState, Dispatch } from "react"

import * as crypto from 'crypto-js'

import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"

import {
  ACCEPTED_FILE_TYPES, CATEGORIES, MAX_FILES_UPLOAD, MAX_FILESIZE_UPLOAD, SLOTS
} from "@/definitions"
import { DISTRICTS, FileWithHash } from "@/types"
import { toastErr } from "@/utils"

import { GridSelect } from "@/components/grid-select"
import { PreviewZoom } from "@/components/preview-zoom"

import { ArrowRight as ArrowRightIcon, ImagePlus as ImagePlusIcon } from "lucide-react"

import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import {
  Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage,
} from "@/components/ui/form"

import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group"
import { Label } from "@/components/ui/label"

import {
  Select, SelectContent, SelectItem, SelectTrigger, SelectValue,
} from "@/components/ui/select"

import { Checkbox } from "@/components/ui/checkbox"
import { Separator } from "@/components/ui/separator"
import { Textarea } from "@/components/ui/textarea"

import { Card, CardContent } from "@/components/ui/card"

export interface OrderFormFields {
  categories?:  Array<string>;
  district?:    string;
  description?: string;
  slots_list?:  Array<string>;
  files?: any
}

const orderFormSchema = z.object({
  categories: z.array(z.string(), {
    required_error: "Selecciona al menos una categoría",
  }),
  district: z.string({
    required_error: "Selecciona una comuna",
  }),
  description: z.string({
    required_error: "Describe tus requerimientos",
  }),
  slots_list: z.array(z.string(), {
    required_error: "Selecciona al menos un horario"
  }),
  files: z.any()
})

interface ImageUploadProps {
  selectedFiles: Array<FileWithHash>;
  setSelectedFiles: Dispatch<Array<FileWithHash>>;
}

// TODO: This only works for one image currently...
function ImageUpload ({selectedFiles, setSelectedFiles}: ImageUploadProps) {
  const onSelectFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
    let files = e.target.files;
    let prevLen = selectedFiles.length;

    if (!files || files.length === 0) {
      return;
    }
    if (prevLen + files.length > MAX_FILES_UPLOAD) {
      toastErr(`Puedes seleccionar como máximo ${MAX_FILES_UPLOAD} archivos`);
      return;
    }

    for (let i = 0; i < files.length; i++) {
      if (files[i].size > MAX_FILESIZE_UPLOAD) {
        toastErr(`El archivo ${files[i].name} es muy pesado (peso: ${(files[i].size / (1 << 20)).toFixed(2)} Mb, máximo: ${(MAX_FILESIZE_UPLOAD / (1 << 20)).toFixed(2)} Mb)`);
        setSelectedFiles([])
        return;
      }
    }

    Array.from(files).forEach(async (file) => {
      let sha256hash = crypto.SHA256(await file.text()).toString();
      let selectedFilesHashes = selectedFiles.map((f) => f.hash);
      if (!(selectedFilesHashes.includes(sha256hash))) {
        let newFile: FileWithHash = {
          "file": file, "preview": URL.createObjectURL(file), "hash": sha256hash
        };
        setSelectedFiles([...selectedFiles, newFile]);
      }
    });
  }

  return (
    <>
      <div className="flex justify-start">
        <label htmlFor="file-upload">
          <div className="flex gap-2 cursor-pointer">
            <ImagePlusIcon/>
            <span>Agregar fotos</span>
          </div>
        </label>
        <Input id="file-upload" className="collapse" accept="image/*" type='file' /*multiple - doesn't work*/ onChange={onSelectFiles} />
      </div>
      { selectedFiles &&
      <div className="flex justify-center mt-5">
        <PreviewZoom
          thumbnail={true}
          urls={selectedFiles.map(obj => {return obj["preview"]})}
          setUrls={(urls: Array<string>) => {
            setSelectedFiles(selectedFiles.filter((obj) => urls.includes(obj["preview"])));
          }}
          />
      </div>
      }
    </>
  )
}

interface OrderFormProps {
  onSubmit: (v: OrderFormFields) => void;
  values:   OrderFormFields;
}

export function OrderForm({ onSubmit, values }: OrderFormProps) {
  const form = useForm<z.infer<typeof orderFormSchema>>({
    resolver: zodResolver(orderFormSchema),
    defaultValues: values
  })

  const [slots, _setSlots] = React.useState(values.slots_list || []);

  const [cats, _setCats] = React.useState(values.categories || []);

  const [files, setFiles] = useState(values.files || [])

  function wrappedOnSubmit(v: z.infer<typeof orderFormSchema>) {
    // Get back to undefined to trigger zod into nice validation
    if (cats.length == 0) {
      return toastErr("Selecciona al menos una categoría");
    } else if (slots.length == 0) {
      return toastErr("Selecciona al menos un horario");
    } else {
      v.slots_list = slots;
      v.categories = cats;
      v.files = files;
      onSubmit(v);
    }
  }

  let categories_options: {[id: string] : string} = {};
  Object.entries(CATEGORIES).map(([key, value]) => {
    categories_options[key] = value['label'];
  });
  return (
    <div className="max-w-[550px]">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(wrappedOnSubmit)} className="space-y-8">
          <FormField
            control={form.control}
            name="categories"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Categorías</FormLabel>
                <GridSelect
                  className="gap-1 grid-cols-2 sm:grid-cols-6"
                  slots={cats}
                  setSlots={
                    (val) => val.length == 0 ? form.setValue("categories", []) : form.setValue("categories", val)
                  }
                  options={categories_options}
                />
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="district"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Comuna</FormLabel>
                <Select onValueChange={field.onChange} defaultValue={field.value}>
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="Selecciona la comuna" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {
                      DISTRICTS.map((value) => {
                        return (
                          <SelectItem key={value} value={value}>{value}</SelectItem>
                        )
                      })}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="description"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Descripción</FormLabel>
                <FormControl>
                  <Textarea
                    className="min-h-[100px]"
                    placeholder="Describe tu proyecto"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="slots_list"
            render={() => (
              <FormItem>
                <div className="mb-4">
                  <FormLabel className="text-base">Horario</FormLabel>
                  <FormDescription>
                    Selecciona todos los horarios de preferencia
                  </FormDescription>
                </div>
                <GridSelect
                  className="gap-1 grid-cols-2"
                  slots={slots}
                  setSlots={
                    (val) => val.length == 0 ? form.setValue("slots_list", []) : form.setValue("slots_list", val)
                  }
                  options={SLOTS}
                />
                <FormMessage/>
              </FormItem>
            )}/>
          <Separator />
          <FormField
            name="files"
            render={() => (
              <FormItem>
                <div className="mb-4">
                  <FormLabel className="text-base">Imágenes</FormLabel>
                  <FormDescription>
                    (Opcional) Máximo 3 imágenes
                  </FormDescription>
                </div>
                <div>
                  <ImageUpload selectedFiles={files} setSelectedFiles={setFiles}/>
                </div>
                <FormMessage/>
              </FormItem>
            )}/>
          <div className="flex">
            <div className="grow"></div>
            <Button type="submit">
              <div className="flex items-center gap-2">
                <ArrowRightIcon/>
                <span>Siguiente</span>
              </div>
            </Button>
          </div>
        </form>
      </Form>
    </div>
  )
}
