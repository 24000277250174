import { formatClp} from "@/utils"

import {
  Tabs, TabsContent, TabsList, TabsTrigger,
} from "@/components/ui/tabs"

import {
  Table, TableBody, TableCaption, TableCell, TableFooter, TableHead,
  TableHeader, TableRow,
} from "@/components/ui/table"

import { Plan, Requirement } from "@/types"

export function PriceTables({plan}: {plan: Plan}) {
  if (plan.requirements && plan.requirements_raw) {
    return (
      <Tabs defaultValue="customer">
        <TabsList className="grid w-full grid-cols-2">
          <TabsTrigger value="partner">Partner</TabsTrigger>
          <TabsTrigger value="customer">Cliente</TabsTrigger>
        </TabsList>
        <TabsContent value="partner">
          <PriceTable reqs={plan.requirements_raw} />
        </TabsContent>
        <TabsContent value="customer">
          <PriceTable reqs={plan.requirements} />
        </TabsContent>
      </Tabs>
    );
  } else if (plan.requirements) {
    return (<PriceTable reqs={plan.requirements} />);
  } else {
    return (<PriceTable reqs={plan.requirements_raw} />);
  }
}

export function PriceTable({reqs}: {reqs: Requirement}) {
  return (
    <div className="overflow-x-auto">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead colSpan={3}>Item</TableHead>
            <TableHead className="text-right" colSpan={1}>Valor</TableHead>
          </TableRow>
        </TableHeader>
        <TableRow>
          <TableCell colSpan={3}>Mano de obra</TableCell>
          <TableCell className="text-right">{formatClp(reqs.manpower)}</TableCell>
        </TableRow>
      </Table>
      { reqs.materials.length != 0 &&
      <Table className="mt-5">
        <TableHeader>
          <TableRow>
            <TableHead>Material</TableHead>
            <TableHead className="w-[80px] text-right">Cantidad</TableHead>
            <TableHead className="text-right">Precio referencial</TableHead>
            <TableHead className="text-right">Valor</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {
            reqs.materials.map((mat, idx) => {
              return <TableRow>
                <TableCell className="font-medium">{mat.material.name}</TableCell>
                <TableCell className="text-right">{mat.quantity}</TableCell>
                <TableCell className="text-right">{formatClp(mat.unit_price)}</TableCell>
                <TableCell className="text-right">{formatClp(mat.total)}</TableCell>
              </TableRow>
            })}
          <TableRow>
            <TableCell colSpan={3} className="font-medium text-right">Total materiales</TableCell>
            <TableCell colSpan={1} className="text-right font-medium">{formatClp(reqs.materials_total)}</TableCell>
          </TableRow>
        </TableBody>
      </Table>}
      <Table className="mt-5">
        <TableBody>
          <TableRow>
            <TableCell className="text-base" colSpan={3}>Total neto</TableCell>
            <TableCell className="text-right text-base">{formatClp(reqs.total)}</TableCell>
          </TableRow>
          { reqs.iva &&
          <TableRow>
            <TableCell colSpan={3}>I.V.A. (19%)</TableCell>
            <TableCell className="text-right">{formatClp(reqs.iva)}</TableCell>
          </TableRow>
          }
        </TableBody>
        { reqs.grand_total &&
        <TableFooter>
          <TableRow>
            <TableCell className="font-bold text-lg" colSpan={3}>Total</TableCell>
            <TableCell className="text-right font-bold text-lg">{formatClp(reqs.grand_total)}</TableCell>
          </TableRow>
        </TableFooter>
        }
      </Table>
    </div>
  )
}
