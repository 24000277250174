// 3rd party imports
import { useEffect, useState } from "react"
import parse from 'html-react-parser';
import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"

// UI imports
import { Check as CheckIcon } from "lucide-react"
import { Checkbox } from "@/components/ui/checkbox"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { Textarea } from "@/components/ui/textarea"
import {
  Form, FormControl, FormField, FormItem, FormLabel, FormMessage,
} from "@/components/ui/form"

import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group"
import { Button } from "@/components/ui/button"
import {
  Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle,
  DialogTrigger
} from "@/components/ui/dialog"

// App imports
import { HANDIFY_API_ENDPOINT, CONTACT_EMAIL } from "@/definitions"
import HandifyLogoSimple from '@/components/logo/handify'
import { TicketDetails } from "@/components/ticket"
import LoadingButton from "@/components/loading-button"
import { Loading } from "@/pages/loading"
import { NotFound } from "@/pages/not-found"
import { Title } from "@/components/title"
import { dictToStr, toastErr, formatTimestamp, getCsrfTokenFromCookie } from "@/utils"
import { AccessToken, Address, District, Ticket, TICKET_STATUS, Uuid } from "@/types"

const GENERIC_PAY_MSG = `Error al iniciar un pago! Por favor contáctanos a ${CONTACT_EMAIL}`;
const GENERIC_ACK_MSG = `Error al confirmar la entrega! Por favor contáctanos a ${CONTACT_EMAIL}`;

const addressFormSchema = z.object({
  line_1: z.string({
    required_error: "Ingresa la dirección",
  }).max(128),
  line_2: z.string().optional(),
  housing: z.string({
    required_error: "Selecciona el tipo",
  }),
  parking: z.boolean().optional(),
  floor: z.coerce.number({
    invalid_type_error: "Ingresa el piso",
    required_error: "Ingresa el piso",
  }).positive(),
});

interface AddressFormProps {
  token:           AccessToken;
  existingAddress: Address;
  district:        District;
}

export function AddressForm({ token, existingAddress, district }: AddressFormProps) {
  const form = useForm<z.infer<typeof addressFormSchema>>({
    resolver: zodResolver(addressFormSchema),
    defaultValues: {
      line_1: existingAddress?.line_1,
      line_2: existingAddress?.line_2,
      parking: existingAddress ? existingAddress.parking : false,
      floor: existingAddress?.floor,
      housing: existingAddress?.housing,
    }
  });

  const [loading, setLoading] = useState(false);
  const [webpayForm, setWebpayForm] = useState<React.ReactNode>(null);

  // When the webpay form is ready, submit it
  useEffect(() => {
    if (webpayForm != null) {
      let form = document.getElementById("webpay_form");
      if (!(form instanceof HTMLFormElement)) {
        return toastErr(GENERIC_PAY_MSG, null);
      } else {
        form.submit();
      }
    }
  },[webpayForm])

  async function beginPayment(values: z.infer<typeof addressFormSchema>) {
    setLoading(true);
    let data = {
      "token": token,
      "address": {...values}
    };
    await fetch(`${HANDIFY_API_ENDPOINT}/api/tickets/pay`, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify(data),
      headers: {
        'X-CSRFToken': getCsrfTokenFromCookie(),
        'Content-type': 'application/json; charset=UTF-8',
      },
    }).then((response) => {
      response.json().then(data => {
        if (response.ok) {
          let form = parse(data['webpay_form']);
          setWebpayForm(form);
        } else {
          setLoading(false);
          return toastErr(GENERIC_PAY_MSG, dictToStr(data));
        }
      }).catch((err) => {
        toastErr(GENERIC_PAY_MSG, err.message);
      })
    });
  }

  return (
    <Form {...form}>
      {webpayForm}
      <form onSubmit={form.handleSubmit(beginPayment)} className="space-y-3">
        <div className="flex flex-wrap items-center justify-between">
          <div>
            <FormField
              control={form.control}
              name="housing"
              render={({ field }) => (
                <FormItem className="space-y-3">
                  <FormControl>
                    <RadioGroup
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                      className="flex flex-col space-y-1"
                    >
                      <FormItem className="flex items-center space-x-3 space-y-0">
                        <FormControl>
                          <RadioGroupItem value="HOUSE" />
                        </FormControl>
                        <FormLabel className="font-normal">Casa</FormLabel>
                      </FormItem>
                      <FormItem className="flex items-center space-x-3 space-y-0">
                        <FormControl>
                          <RadioGroupItem value="FLAT" />
                        </FormControl>
                        <FormLabel className="font-normal">Departamento</FormLabel>
                      </FormItem>
                      <FormItem className="flex items-center space-x-3 space-y-0">
                        <FormControl>
                          <RadioGroupItem value="OFFICE" />
                        </FormControl>
                        <FormLabel className="font-normal">Oficina</FormLabel>
                      </FormItem>
                    </RadioGroup>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div>
            <FormField
              control={form.control}
              name="parking"
              render={({ field }) => (
                <FormItem className="flex flex-row items-start space-x-3 space-y-0">
                  <FormControl>
                    <Checkbox
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                  <div className="space-y-1 leading-none">
                    <FormLabel>
                      Hay estacionamiento
                    </FormLabel>
                  </div>
                </FormItem>
              )}
            />
          </div>
        </div>
        <FormField
          control={form.control}
          name="line_1"
          render={({ field }) => (
            <FormItem>
              <div className="grid grid-cols-4 items-center gap-4">
                <Label htmlFor="line_1" className="text-right">
                  Dirección
                </Label>
                <FormControl className="col-span-3">
                  <Input {...field}/>
                </FormControl>
              </div>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="line_2"
          render={({ field }) => (
            <FormItem>
              <div className="grid grid-cols-4 items-center gap-4">
                <Label htmlFor="line_2" className="text-right">
                  Complemento
                </Label>
                <FormControl className="col-span-3">
                  <Input placeholder='Opcional' {...field}/>
                </FormControl>
              </div>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="floor"
          render={({ field }) => (
            <FormItem>
              <div className="grid grid-cols-4 items-center gap-4">
                <Label htmlFor="line_2" className="text-right">
                  Piso
                </Label>
                <FormControl className="col-span-3">
                  <Input type="number" {...field}/>
                </FormControl>
              </div>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="grid grid-cols-4 items-baseline gap-4">
          <Label htmlFor="district" className="text-right">
            Comuna
          </Label>
          <div className="col-span-3">
            <>{district}</>
          </div>
        </div>
        <div className="pt-5">
          <LoadingButton className="w-full" text="Ir al pago" loading={loading} />
        </div>
      </form>
    </Form>
  )
}

function PayTicketDialog({token, ticket}: {token: AccessToken, ticket: Ticket}) {
  const [open, setOpen] = useState(false);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button className="text-xl font-bold w-full">Confirmar visita <CheckIcon /></Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Dirección</DialogTitle>
        </DialogHeader>
        <DialogDescription>
          Necesitamos la dirección <span className='font-bold'>donde se realizarán los trabajos.</span>
        </DialogDescription>
        <AddressForm token={token} existingAddress={ticket.address} district={ticket.order.district}/>
      </DialogContent>
    </Dialog>
  )
}

function CloseTicketDialog({uuid}: {uuid: Uuid}) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const closeFormSchema = z.object({
    customer_closure: z.string().optional()
  });

  const form = useForm<z.infer<typeof closeFormSchema>>({
    resolver: zodResolver(closeFormSchema)
  });

  async function acknowledgeTicket(values: z.infer<typeof closeFormSchema>) {
    setLoading(true);
    await fetch(`${HANDIFY_API_ENDPOINT}/api/tickets/${uuid}/acknowledge`, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify(values),
      headers: {
        'X-CSRFToken': getCsrfTokenFromCookie(),
        'Content-type': 'application/json; charset=UTF-8',
      },
    }).then((response) => {
      response.json().then(data => {
        if (response.ok) {
          window.location.reload();
        } else {
          return toastErr(GENERIC_ACK_MSG, dictToStr(data));
        }
      }).catch((err) => {
        toastErr(GENERIC_ACK_MSG, err.message);
      });
    });
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild className="flex w-full justify-center">
        <Button className="text-xl p-5">
          Confirmar entrega
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Confirmar entrega</DialogTitle>
        </DialogHeader>
        <DialogDescription>
          El ticket se dará por cerrado y recibirás un email de confirmación.
        </DialogDescription>
        <Form { ... form }>
          <form onSubmit={form.handleSubmit(acknowledgeTicket)}>
            <FormField
              control={form.control}
              name="customer_closure"
              render={({ field }) => (
              <FormItem>
                <FormLabel>
                  Nos gustaría conocer tu opinión
                </FormLabel>
                <FormControl>
                  <Textarea
                    className="min-h-[100px]"
                    placeholder="Comentario de cierre (opcional)"
                    {... field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>)}
              />
            <FormItem className="pt-4 flex justify-end">
              <LoadingButton type="submit" className="w-full" text="Confirmar entrega" loading={loading}/>
            </FormItem>
            </form>
          </Form>
        </DialogContent>
      </Dialog>
  );
}

export function TicketCustomer({token}: {token: AccessToken}) {
  const [loading, setLoading] = useState(true);
  const [ticket, setTicket] = useState<Ticket | null>();

  useEffect(() => {
    const fetchTicket = async () => {
      await fetch(`${HANDIFY_API_ENDPOINT}/api/tickets/review?token=${token}`, {
        method: 'GET',
      }).then((response) => {
        if (response.ok) {
          response.json().then(data => {
            let t: Ticket = { ...data };
            setTicket(t);
            setLoading(false);
          }).catch((err) => {
            return toastErr("El servidor dice:", err.message);
          });
        } else {
          setTicket(null);
          setLoading(false);
        }
      }).catch((err) => {
        return toastErr("El servidor dice:", err.message);
      });
    }

    fetchTicket();
  }, [token])

  if (loading) {
    return <Loading />
  }

  if (ticket == null) {
    return <NotFound />
  }

  let dialog = <></>;
  switch (ticket.status) {
    case TICKET_STATUS.STANDBY:
      dialog = <PayTicketDialog token={token} ticket={ticket}/>;
      return (
        <>
          <Title child={<>Propuesta</>}/>
          <div className="flex flex-wrap justify-center gap-7">
            <div className="flex-shrink p-2 max-w-[430px]">
              <h2>Estimado cliente,</h2>
              <p className="mt-3">En esta página podrás encontrar los detalles de tu pedido y la propuesta realizada por nuestros Handymen.</p>
              <p className="mt-3">Por favor revisa esta propuesta y confirma la visita programada para el día <span className="font-bold">{formatTimestamp(ticket.plan.proposal.first_visit, true)}</span> realizando el pago.</p>
              <p className="mt-3">Esperamos trabajar contigo!</p>
              <HandifyLogoSimple className="max-w-40 mx-auto h-auto mt-5 mb-3"/>
              <p className="mt-5 text-center">El equipo de Handify</p>
            </div>
            <TicketDetails ticket={ticket} dialogs={dialog} token={token}/>
          </div>
        </>
      );
    case TICKET_STATUS.INPROGRESS:
      return (
        <>
          <Title child={<>Ticket #{ticket.short_uuid}</>} className="pt-10"/>
          <div className="flex flex-wrap justify-center gap-7 mb-4">
            <div className="flex-shrink p-3 max-w-[430px] font-[family-name:var(--font-geist-sans)]">
              <h2>Estimado cliente,</h2>
              <p className="mt-3">En esta página podrás encontrar todos los detalles de tu proyecto.</p>
              <p className="mt-3">No dudes en contactarnos!</p>
              <HandifyLogoSimple className="max-w-40 mx-auto h-auto mt-5 mb-3"/>
              <p className="mt-5 text-center">El equipo de Handify</p>
            </div>
            <TicketDetails ticket={ticket} dialogs={dialog} token={token}/>
          </div>
        </>
      );
    case TICKET_STATUS.WAITACK:
      dialog = <CloseTicketDialog uuid={ticket.uuid}/>
      return (
        <>
          <Title child={<>Ticket #{ticket.short_uuid}</>} className="pt-10"/>
          <div className="flex flex-wrap justify-center gap-7 mb-4">
            <div className="flex-shrink p-3 max-w-[430px] font-[family-name:var(--font-geist-sans)]">
              <h2>Estimado cliente,</h2>
              <p className="mt-3">Tu proyecto ha finalizado. Por favor, confirma que la entrega fue conforme.</p>
              <p className="mt-3">No dudes en contactarnos!</p>
              <HandifyLogoSimple className="max-w-40 mx-auto h-auto mt-5 mb-3"/>
              <p className="mt-5 text-center">El equipo de Handify</p>
            </div>
            <TicketDetails ticket={ticket} dialogs={dialog} token={token}/>
          </div>
        </>
      );
    case TICKET_STATUS.CLOSED:
      return (
        <>
          <Title child={<>Ticket #{ticket.short_uuid}</>} className="pt-10"/>
          <div className="flex flex-wrap mb-4 justify-center gap-7 mb-4">
            <div className="flex-shrink p-3 max-w-[430px] font-[family-name:var(--font-geist-sans)]">
              <HandifyLogoSimple className="max-w-40 mx-auto h-auto mb-8 mb-3"/>
              <h2>Estimado cliente,</h2>
              <p className="mt-3">Este ticket está cerrado. Gracias por confiar en Handify!</p>
            </div>
          </div>
        </>
      );
    default:
      return <NotFound />
  }
}
