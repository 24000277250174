import { Badge } from "@/components/ui/badge"

import { CATEGORIES } from "@/definitions"

interface CategoryBadgesProps {
  categories: Array<string>;
  className?: string;
}

export function CategoryBadges({categories, className}: CategoryBadgesProps) {
  return (
    <div className={`flex flex-wrap ${className}`}>
      {
        categories.map(cat => {
          let color = CATEGORIES[cat]['color'];
          return <Badge key={`cat-${cat}`} className={`m-[1px] bg-${color}-400 hover:bg-${color}`}>{CATEGORIES[cat]['label']}</Badge>
      })
      }
    </div>
  )
}

// This function is unused, it is only to load the colors...
function ColorHack() {
  return (
    <div>
      <Badge className="bg-yellow-400"></Badge>
      <Badge className="bg-orange-400"></Badge>
      <Badge className="bg-blue-400"></Badge>
      <Badge className="bg-emerald-400"></Badge>
      <Badge className="bg-indigo-400"></Badge>
      <Badge className="bg-lime-400"></Badge>
    </div>
  )
}
