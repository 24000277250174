import { useEffect, useState } from "react"

import { Switch } from "@/components/ui/switch"
import { Label } from "@/components/ui/label"

import { HANDIFY_API_ENDPOINT } from "@/definitions"
import { Order, ORDER_STATUS } from "@/types"
import { toastErr } from "@/utils"

import { Loading } from "@/pages/loading"
import { Forbidden } from "@/pages/forbidden"
import { Title } from "@/components/title"
import { OrderDetails } from "@/components/order"
import LoadingButton from "@/components/loading-button"

export default function Dashboard() {
  const [hasAccess, setHasAccess] = useState(false);
  const [dataReady, setDataReady] = useState(false);
  const [allOrders, setAllOrders] = useState<Array<Order>>([]);
  const [filteredOrders, setFilteredOrders] = useState<Array<Order>>([]);
  const [loading, setLoading] = useState(false);
  const [nextPageUrl, setNextPageUrl] = useState("#");

  useEffect(() => {
    const fetchOrders = async () => {
      await fetch(`${HANDIFY_API_ENDPOINT}/api/tickets/orders/`, {
        method: 'GET',
        credentials: 'include'
      }).then((response) => {
        if (response.ok) {
          response.json().then(data => {
            setDataReady(true);
            setHasAccess(true);
            setAllOrders(data["results"]);
            setFilteredOrders(
              data["results"].filter((order: Order) => {
                return order.status == ORDER_STATUS.BACKLOG;
              })
            );
            setNextPageUrl(data["next"]);
          });
        } else {
          setDataReady(true);
          setHasAccess(false);
        }
      }).catch((err) => {
        toastErr("algo no está bien", err.message);
      });
    };
    fetchOrders()
  }, []);

  if (!dataReady) {
    return <Loading />
  }

  if (!hasAccess) {
    return <Forbidden />
  }

  function toggleShowHistory(checked: boolean) {
    if (checked) {
      setFilteredOrders(allOrders);
    } else {
      setFilteredOrders(
        allOrders.filter(order => {
          return order.status == ORDER_STATUS.BACKLOG;
        })
      );
    }
  }

  function getMoreOrders(e: React.MouseEvent) {
    e.preventDefault();
    setLoading(true);
    fetch(nextPageUrl, {
      method: 'GET',
      credentials: 'include'
    }).then((response) => {
      if (response.ok) {
        response.json().then(data => {
          setAllOrders(allOrders => [...allOrders, ...data["results"]]);
          let filtered = data["results"].filter((order: Order) => {
            return order.status == ORDER_STATUS.BACKLOG;
          })
          setFilteredOrders([...filteredOrders, ...filtered])
          setNextPageUrl(data["next"]);
        });
      } else {
        response.json().then(data => {
          toastErr("Algo salió mal...", JSON.stringify(data, null, 2))
        })
      }
      setLoading(false);
    }).catch((err) => {
      toastErr("algo no está bien", err.message);
    });
  }

  return (
    <div className="p-2">
      <Title child={
      <div className="flex flex-col gap-6 pb-6">
        <div>Backlog</div>
        <div className="flex items-center space-x-2">
          <Switch id="show_accepted_id" onCheckedChange={toggleShowHistory}/>
          <Label htmlFor="show_accepted_id">Mostrar órdenes pasadas</Label>
        </div>
      </div>
      }/>
      <div className="flex flex-wrap justify-center gap-8">
        {
          filteredOrders.map((order) => {
            return <div> <OrderDetails order={order} /> </div>
          })
        }
      </div>
      {
        nextPageUrl &&
          <div className="flex flex-wrap justify-center p-5">
            <LoadingButton className="font-semibold min-w-[300px] text-xl p-5" onClick={getMoreOrders} loading={loading} text="Ver más pedidos"/>
          </div>
      }
    </div>
  );}
