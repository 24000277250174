import LogoFull from '@/assets/images/logo/png/logo_full.png'
import Logo from '@/assets/images/logo/svg/logo.svg'

export default function HandifyLogoSimple({className = ""}) {
    return ( <img className={className} src={Logo} alt="logo" /> );
}

export function HandifyLogoFull({className = ""}) {
    return ( <img className={className} src={LogoFull} alt="logo_full" /> );
}

