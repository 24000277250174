// 3rd party imports
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"

// UI imports
import { Frown as FrownIcon } from "lucide-react"

// App imports
import { CONTACT_EMAIL, HANDIFY_API_ENDPOINT } from "@/definitions"
import { toastErr } from "@/utils"
import { Loading } from "@/pages/loading"
import HandifyLogoSimple from '@/components/logo/handify'
import LoadingButton from "@/components/loading-button"
import { AccessToken, TransactionStatus, TRANSACTION_STATUS, Uuid } from "@/types"

export default function TransactionResult() {
  const {uuid} = useParams<{ uuid: Uuid }>();
  const [ready, setReady] = useState<boolean>(false);
  const [status, setStatus] = useState<TransactionStatus>();
  const [email, setEmail] = useState<string>();
  const [token, setToken] = useState<AccessToken>();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTicket = async () => {
      await fetch(`${HANDIFY_API_ENDPOINT}/api/tickets/transaction/${uuid}`, {
        method: 'GET',
      }).then((response) => {
        setReady(true);
        if (response.ok) {
          response.json().then(data => {
            setStatus(data["status"]);
            setToken(data["token"]);
            setEmail(data["email"]);
          })
        } else {
          return toastErr("Error al verificar la transacción");
        }
      }).catch((err) => {
        return toastErr("El servidor dice:", err.message);
      });
    }

    fetchTicket();
  })

  if (!ready) {
    return <Loading />
  }

  let payed = false;
  switch (status) {
    case TRANSACTION_STATUS.PAYED:
      payed = true;
      break;
    case TRANSACTION_STATUS.INITIALIZED:
      break;
    case TRANSACTION_STATUS.ERROR:
      break;
  }
  const goToTicket = () => {
    setLoading(true);
    navigate(`/tickets?token=${token}`);
  }

  if (payed) {
    return (
      <div className="grid grid-rows-[20px_1fr_20px] justify-items-center mt-0 pt-0 p-8 gap-16 sm:p-0 max-w-50">
        <div className="flex flex-col gap-8 row-start-2 justify-items-center items-center">
          <HandifyLogoSimple className="max-w-40 mx-auto h-auto"/>
          <h1 className="max-w-[350px] text-center">Tu visita está confirmada!</h1>
            <p className="max-w-[400px] text-justify">Muchas gracias! Puedes ver todos los detalles en el ticket que hemos creado para ti:</p>
          <LoadingButton onClick={goToTicket} loading={loading} text="Ver ticket" />
          <p className="max-w-[400px] text-justify">Además te hemos enviado un correo de confirmación a <span className="font-bold">{email}</span>.</p>
        </div>
      </div>
    )
  } else {
    return (
      <div className="grid grid-rows-[20px_1fr_20px] justify-items-center mt-0 pt-0 p-8 gap-16 sm:p-0 font-[family-name:var(--font-geist-sans)] max-w-50">
        <div className="flex flex-col gap-8 row-start-2 justify-items-center items-center">
          <FrownIcon size={48} />
          <h1 className="max-w-[350px] text-center">La transacción ha fallado</h1>
          <p className="max-w-[400px] text-justify">
            Tu pago no ha sido procesado correctamente. Por favor, inténtalo nuevamente o contáctanos a {CONTACT_EMAIL}.
          </p>
          <LoadingButton onClick={goToTicket} loading={loading} text="Intentarlo nuevamente" />
        </div>
      </div>
    )
  }
}
