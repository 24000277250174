import HandifyLogoSimple from '@/components/logo/handify'

export default function Thanks() {
  return (
    <div className="grid justify-items-center items-center min-h-[300px] font-[family-name:var(--font-geist-sans)]">
      <div className="flex flex-col gap-8 items-center">
          <HandifyLogoSimple className="max-w-40"/>
        <h3 className="max-w-[350px] text-center text-large">Nuestros handymen estarán en contacto muy pronto.</h3>
      </div>
    </div>
  );
}
