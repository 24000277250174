import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

import { X as XIcon } from "lucide-react"

import { Dispatch } from "react"

interface PreviewZoomProps {
  urls: Array<string>;
  setUrls?: Dispatch<Array<string>>;
  thumbnail: boolean;
}

export function PreviewZoom({urls, setUrls, thumbnail}: PreviewZoomProps) {
  return (
    <div className="flex flex-wrap justify-center items-center gap-1 rounded border-1">
      {urls.map((url) => (
        <div key={url} className={ thumbnail ? "max-w-[48%] sm:max-w-[32%] relative" : "sm:max-w-[48%]"}>
          { setUrls &&
          <XIcon onClick={() => {setUrls(urls.filter((u) => u !== url))}} className="absolute top-2 right-2 cursor-pointer"/>
          }
          <Zoom>
            <img className="aspect-auto" src={url} alt=""/>
          </Zoom>
        </div>
      ))}
    </div>
  )
}
