// https://stackoverflow.com/questions/79240086/
type ValuesCombined<T extends object> = T[keyof T];

type UserAccess = "staff" | "partner" | "public"

export type LoggedInUser = {
  first_name?: string;
  last_name?:  string;
  email:       string;
  access:      UserAccess;
}
type UnknownUser = {
  user: string
}
export type User = LoggedInUser | UnknownUser;

export const DISTRICTS = [
  'Colina',
  'Estación central',
  'Independencia',
  'La Reina',
  'Las Condes',
  'Lo Barnechea',
  'Macul',
  'Ñuñoa',
  'Pedro Aguirre Cerda',
  'Providencia',
  'Quinta Normal',
  'Recoleta',
  'San Joaquín',
  'San Miguel',
  'Santiago',
  'Vitacura'
] as const

export type District = ValuesCombined<typeof DISTRICTS>;

export type Category = {
  label: string;
  color: string;
}

export const ORDER_STATUS = {
  BACKLOG:  'BACKLOG',
  ACCEPTED: 'ACCEPTED',
  DENIED:   'DENIED'
} as const

export type OrderStatus = ValuesCombined<typeof ORDER_STATUS>;

export type Uuid = string;
export type AccessToken = string;

export const TICKET_STATUS = {
  DRAFT:      'DRAFT',
  OPEN:       'OPEN',
  ASSIGNED:   'ASSIGNED',
  STANDBY:    'STANDBY',
  EXPIRED:    'EXPIRED',
  RETRACTED:  'RETRACTED',
  INPROGRESS: 'INPROGRESS',
  BLOCKED:    'BLOCKED',
  WAITACK:    'WAITACK',
  CONFLICT:   'CONFLICT',
  CLOSED:     'CLOSED',
  REOPENED:   'REOPENED',
} as const;

export type TicketStatus = ValuesCombined<typeof TICKET_STATUS>;

export const TICKET_ACCESS = {
  STAFF:   "staff",
  PARTNER: "partner",
  TOKEN:   "token",
  PUBLIC:  "public",
} as const

export interface Customer {
  uuid:       string;
  phone:      string;
  email:      string;
  first_name: string;
  last_name:  string;
  orders:     Array<string>;
}

export interface Partner {
  uuid:         string;
  email:        string;
  first_name:   string;
  last_name:    string;
  fantasy_name: string;
  created_at:   string;
}

export type FileWithHash = {
  file: File;
  preview: string;
  hash: string;
}

export interface Order {
  uuid:          Uuid;
  status:        OrderStatus;
  categories:    Array<string>;
  slots_list:    Array<string>;
  description:   string;
  created_at:    string;
  reason_denied: string;
  district:      District;
  customer:      Customer;
  ticket:        Ticket;
  ticket_url:    string;
  media:         Array<string>;
}

export interface TicketLogEntry {
  t:   string;
  log: string;
}

export interface Ticket {
  uuid:               string;
  short_uuid:         string;
  access:             string;
  status:             TicketStatus;
  manager_url:        string;
  order:              Order;
  categories:         Array<string>;
  plan:               Plan;
  manager_comment:    string;
  manager_closure:    string;
  partner_closure:    string;
  customer_closure:   string;
  reason_blocked:     string;
  created_at:         string;
  is_payed:           boolean;
  closed_at:          string;
  address:            Address;
  token:              string;
  // readonly plan:            Plan;
  ticket_log_entries: Array<TicketLogEntry>;
  format:                      string;
}

export interface Requirement {
  demands:         string;
  materials:       Array<Material>;
  materials_total: number;
  manpower:        number;
  iva:             number;
  total:           number;
  grand_total:     number;
}

export interface Material {
  material:   { name: string };
  unit_price: number;
  quantity:   number;
  total:      number;
}

export interface Materials {
  mats: Array<Material>;
  total: number
}

export interface Plan {
  requirements:     Requirement;
  candidates:       Array<Uuid>;
  requirements_raw: Requirement;
  proposal:         Proposal;
  revenue:          number;
}

export interface Proposal {
  readonly partner:      Partner;
  readonly created_at:   string;
  readonly expires_at?:  string;
  readonly first_visit:  string;
  readonly manpower:     string;
  readonly requirements: Requirement;
}

export type Housing =
  | 'HOUSE'
  | 'FLAT'
  | 'OFFICE';


export interface Address {
  line_1:  string;
  line_2:  string;
  floor:   number;
  housing: Housing;
  parking: boolean;
}

export const TRANSACTION_STATUS = {
  INITIALIZED: 'INITIALIZED',
  ERROR: 'ERROR',
  PAYED : 'PAYED'
} as const
export type TransactionStatus = ValuesCombined<typeof TRANSACTION_STATUS>;
