import { Category } from "@/types"

export const CATEGORIES: {[id: string]: Category} = {
  'EL': { 'label': 'Electricidad',  'color': 'yellow' },
  'CR': { 'label': 'Carpintería',   'color': 'orange' },
  'PL': { 'label': 'Gasfitería',    'color': 'blue' },
  'PT': { 'label': 'Pintura',       'color': 'emerald' },
  'IN': { 'label': 'Instalaciones', 'color': 'indigo' },
  'OT': { 'label': 'Otros',        'color': 'lime' },
} as const

export const HOUSING_TYPE: {[id:string]: string} = {
  'HOUSE':  'Casa',
  'FLAT':   'Departamento',
  'OFFICE': 'Oficina',
} as const

export const MAX_FILES_UPLOAD = 3;
export const MAX_FILESIZE_UPLOAD = 5 * (1 << 10) * (1 << 10); // 5 MB
export const ACCEPTED_FILE_TYPES = ['image/png'] as const;

export const SLOTS: {[id: string]: string} = {
  "MON-AM": "Lunes AM",
  "MON-PM": "Lunes PM",
  "TUE-AM": "Martes AM",
  "TUE-PM": "Martes PM",
  "WED-AM": "Miércoles AM",
  "WED-PM": "Miércoles PM",
  "THU-AM": "Jueves AM",
  "THU-PM": "Jueves PM",
  "FRI-AM": "Viernes AM",
  "FRI-PM": "Viernes PM",
  "SAT-AM": "Sábado AM",
  "SAT-PM": "Sábado PM",
} as const

export const WHATSAPP_URL = process.env.REACT_APP_HANDIFY_WHATSAPP_URL || "#"
export const CONTACT_EMAIL = process.env.REACT_APP_HANDIFY_CONTACT_EMAIL || "#"
export const INSTAGRAM_URL = process.env.REACT_APP_HANDIFY_INSTAGRAM_URL || "#"
export const FACEBOOK_URL = INSTAGRAM_URL

export const HANDIFY_API_ENDPOINT      = process.env.REACT_APP_HANDIFY_API_ENDPOINT
export const HANDIFY_FRONTEND_ENDPOINT = process.env.REACT_APP_HANDIFY_FRONTEND_ENDPOINT
