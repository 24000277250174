import { useContext, useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom"

import { HANDIFY_API_ENDPOINT } from "@/definitions"

import { fetchUser, getCsrfTokenFromCookie, toastErr, toastOk, userContext } from "@/utils"

import { LoginForm, LoginFormFields } from "@/components/login-form"
import HandifyLogoSimple from '@/components/logo/handify'

export default function Login() {
  const navigate = useNavigate();
  const goToDashboard = () => { navigate("/tickets", { replace: true }); }
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchCsrfToken = async () => {
      await fetch(`${HANDIFY_API_ENDPOINT}/auth/login/`, {
        credentials: 'include',
        method: 'GET',
      }).then((response) => {
        if (!response.ok) {
          return toastErr("Algo ha salido mal")
        }
      }).catch((err) => {
        toastErr(null, err.message);
      });
    };
    fetchCsrfToken()
  }, []);

  const setUser = useContext(userContext).setUser;

  function loginOnSubmit(values: LoginFormFields) {
    values["username"] = values["email"];
    fetch(`${HANDIFY_API_ENDPOINT}/auth/login/`, {
      credentials: 'include',
      method: 'POST',
      body: new URLSearchParams({...values}),
      headers: {
        'X-CSRFToken': getCsrfTokenFromCookie(),
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
    }).then((response) => {
      response.text().then(data => {
        // This is included in the DRF HTML response if the credentials are
        // wrong.
        let hack_msg = "Please enter a correct";
        if (data.includes(hack_msg)) {
          toastErr("Credenciales incorrectas");
        } else {
          toastOk("Sesión iniciada correctamente");
          fetchUser(setUser);
          goToDashboard()
        }
        setLoading(false);
      });
    }).catch((err) => {
      toastErr(null, err.message)
    });
  }

  return (
      <div className="flex bg-white flex-wrap justify-center items-center sm:gap-20 p-10 sm:p-32">
        <div>
          <div className="text-center text-3xl">Iniciar sesión</div>
          <HandifyLogoSimple className="max-w-40 h-auto m-3"/>
        </div>
        <div className="grid justify-center p-3">
          <LoginForm onSubmit={loginOnSubmit} loading={loading} setLoading={setLoading}/>
        </div>
      </div>
  );
}
