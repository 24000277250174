import { useState } from "react"
import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"

import { Input } from "@/components/ui/input"
import {
  Form, FormControl, FormField, FormItem, FormLabel, FormMessage,
} from "@/components/ui/form"
import LoadingButton from "@/components/loading-button"

const phoneRegex = new RegExp(/^\+\d{9,15}$/);

export interface CustomerFormFields {
  first_name?: string;
  last_name?: string;
  email?: string;
  phone?: string;
}

const customerFormSchema = z.object({
  first_name: z
  .string({ required_error: "" }).max(30),
  last_name: z
  .string({ required_error: "" }).max(50),
  email: z
  .string({
    required_error: "Necesitamos tu email para acusar recibo del pedido"
  }).email({ message: "Email inválido" }),
  phone: z
  .string({ required_error: "" })
  .regex(phoneRegex, "El teléfono debe tener formato +56999999999 (entre 9 y 15 dígitos)")
})

interface CustomerFormProps {
  onSubmit: (v: CustomerFormFields) => void;
}

export function CustomerForm({ onSubmit }: CustomerFormProps) {
  const form = useForm<z.infer<typeof customerFormSchema>>({
    resolver: zodResolver(customerFormSchema)
  })

  const [loading, setLoading] = useState(false);
  async function wrappedOnSubmit(values: z.infer<typeof customerFormSchema>) {
    setLoading(true);
    await onSubmit(values);
  }

  return (
    <div className="min-w-[330px] max-w-[400px] p-3">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(wrappedOnSubmit)} className="space-y-8">
          <FormField
            control={form.control}
            name="first_name"
            render={({ field }) => (
            <FormItem>
              <FormLabel>Nombre</FormLabel>
              <FormControl>
                <Input placeholder="Nombre" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
            )}
            />
          <FormField
            control={form.control}
            name="last_name"
            render={({ field }) => (
            <FormItem>
              <FormLabel>Apellido</FormLabel>
              <FormControl>
                <Input placeholder="Apellido" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
            )}
            />
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
            <FormItem>
              <FormLabel>Correo electrónico</FormLabel>
              <FormControl>
                <Input placeholder="Email" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
            )}
            />
          <FormField
            control={form.control}
            name="phone"
            render={({ field }) => (
            <FormItem>
              <FormLabel>Teléfono</FormLabel>
              <FormControl>
                <Input placeholder="+569" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
            )}
            />
          <LoadingButton type="submit" className="w-full" text="Enviar" loading={loading} />
          </form>
          </Form>
          </div>
  )
}
