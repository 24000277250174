import { ReactNode } from "react"

import {
  BookmarkCheck as BookmarkCheckIcon, Clock as ClockIcon, HardHat as
  HardHatIcon, PencilLine as PencilLineIcon, TriangleAlert as WarningIcon,
  UserCheck as UserCheckIcon, UserCog as UserCogIcon, Ban as BanIcon,
  MessageSquareX as MessageSquareXIcon, RefreshCcw as RefreshCcwIcon
} from "lucide-react"
import { Alert, AlertDescription, AlertTitle, } from "@/components/ui/alert"
import {
  Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle,
} from "@/components/ui/card"

import { HANDIFY_API_ENDPOINT } from "@/definitions"
import { formatTimestamp, formatClp } from "@/utils"
import {
  AccessToken, Ticket, TICKET_ACCESS, TicketStatus, TICKET_STATUS,
  TicketLogEntry
} from "@/types"
import { CategoryBadges } from "@/components/categories"
import { CustomerDetails } from "@/components/customer"
import { PlanDetails } from "@/components/plan"
import { PreviewZoom } from "@/components/preview-zoom"
import { ProposalDetails } from "@/components/proposal"

interface StatusIconProps {
  status:     TicketStatus;
  className?: string;
  size?:     number;
}
export function StatusIcon({status, className, size}: StatusIconProps) {
  switch (status) {
    case TICKET_STATUS.DRAFT:
      return <PencilLineIcon className={className} size={size} />;
    case TICKET_STATUS.OPEN:
      return <UserCogIcon className={className} size={size} />;
    case TICKET_STATUS.ASSIGNED:
      return <UserCheckIcon className={className} size={size} />;
    case TICKET_STATUS.STANDBY:
      return <ClockIcon className={className} size={size} />;
    case TICKET_STATUS.EXPIRED:
      return <BanIcon className={className} size={size} />;
    case TICKET_STATUS.RETRACTED:
      return <MessageSquareXIcon className={className} size={size} />;
    case TICKET_STATUS.INPROGRESS:
        return <HardHatIcon className={className}size={size} />;
    case TICKET_STATUS.BLOCKED:
        return <WarningIcon className={className}size={size} />;
    case TICKET_STATUS.WAITACK:
        return <ClockIcon className={className} size={size} />;
    case TICKET_STATUS.CLOSED:
        return <BookmarkCheckIcon className={className} size={size} />;
    case TICKET_STATUS.REOPENED:
        return <RefreshCcwIcon className={className} size={size} />;
    default:
      return <></>;
  }
}

export function StatusDescription({status}: {status: TicketStatus}) {
  switch (status) {
    case TICKET_STATUS.DRAFT:
      return <>Este ticket <span className="fonr-semibold">no ha sido enviado</span> a los asociados.</>;
    case TICKET_STATUS.OPEN:
      return <>Estamos esperando que un asociado acepte el plan.</>;
    case TICKET_STATUS.STANDBY:
      return <>La visita <span className="font-semibold">aún no está confirmada</span>.</>;
    case TICKET_STATUS.INPROGRESS:
      return <>La visita está <span className="font-semibold">confirmada</span>.</>;
    case TICKET_STATUS.WAITACK:
      return <>El ticket está finalizado, pero estamos esperando confirmación del cliente.</>;
    default:
      return <></>;
  }
}

export function GetInvoiceLink({token} : { token: AccessToken }) {
  return <a
    className="hyperlink text-right"
    href={`${HANDIFY_API_ENDPOINT}/api/tickets/invoice?token=${token}`
    }>
    Ver comprobante de pago
  </a>;
}

interface TicketDetailsProps {
  ticket:   Ticket;
  dialogs?: ReactNode;
  token?:   AccessToken;
}

export function TicketDetails({ticket, dialogs, token}: TicketDetailsProps) {
  return (
    <div className="max-w-[600px] m-2">
      <Card>
        <CardHeader>
          <CardTitle className="text-xl text-center">
            Ticket # {ticket.short_uuid}
          </CardTitle>
          <CardTitle className="text-lg">
            <>Comuna: {ticket.order.district}</>
          </CardTitle>
          <CategoryBadges categories={ticket.categories}/>
          { ticket.uuid && <CardDescription> Referencia: {ticket.uuid} </CardDescription> }
        </CardHeader>
        <CardContent className="">
          <Alert className="mb-4">
            <StatusIcon status={ticket.status}/>
            <AlertTitle className="text-xl">Status: {ticket.status}</AlertTitle>
            <AlertDescription>
              <StatusDescription status={ticket.status} />
            </AlertDescription>
          </Alert>
          { ticket.plan.proposal &&
          <ProposalDetails proposal={ticket.plan.proposal} address={ticket.address} expired={ticket.status === TICKET_STATUS.EXPIRED} />
          }
        </CardContent>
        {
          ticket.order.customer &&
            <div className="flex flex-wrap justify-between gap-8">
              <CardContent className="flex-none pr-0 mr-0 max-w-[150px]">
                <CardTitle className="lone">Detalles cliente</CardTitle>
                <CardDescription>
                  <CustomerDetails customer={ticket.order.customer} />
                </CardDescription>
              </CardContent>
            </div>
        }
        { ticket.order.description &&
        <CardContent className="flex-grow max-w-[600px]">
          <CardTitle className="lone">Descripción original cliente</CardTitle>
          <CardDescription className="max-w-[600px] text-justify">{ticket.order.description}</CardDescription>
        </CardContent>
        }
        { ticket.order.media && ticket.order.media.length > 0 &&
        <CardContent>
          <CardTitle>Media</CardTitle>
          <div className="flex justify-center mt-5">
            <PreviewZoom thumbnail={true} urls={ticket.order.media} />
          </div>
        </CardContent>
        }
        {
          ticket.manager_comment &&
            <CardContent className="max-w-[600px]">
              <CardTitle className="lone">Comentario manager</CardTitle>
              <CardDescription className="text-justify">{ticket.manager_comment}</CardDescription>
            </CardContent>
        }
        { ticket.manager_closure &&
        <CardContent className="max-w-[600px]">
          <CardTitle className="lone">Comentario de cierre (manager)</CardTitle>
          <CardDescription className="text-justify">{ticket.manager_closure}</CardDescription>
        </CardContent>
        }
        { ticket.partner_closure &&
          <CardContent className="max-w-[600px]">
            <CardTitle className="lone">Comentario de cierre (asociado)</CardTitle>
            <CardDescription className="text-justify">{ticket.partner_closure}</CardDescription>
          </CardContent>
        }
        { ticket.customer_closure &&
          <CardContent className="max-w-[600px]">
            <CardTitle>Comentario de cierre (cliente)</CardTitle>
            <CardDescription className="text-justify">{ticket.customer_closure}</CardDescription>
          </CardContent>
        }
        {
          ticket.reason_blocked &&
            <CardContent>
              <CardTitle>Razón de bloqueo</CardTitle>
              <CardDescription>{ticket.reason_blocked}</CardDescription>
            </CardContent>
        }
        { ticket.plan && <PlanDetails plan={ticket.plan}/> }
        {
          ticket.access === "staff" && ticket.plan && ticket.plan.revenue &&
            <CardContent>
              <h1 className="text-right">Ingreso: {formatClp(ticket.plan.revenue)}</h1>
            </CardContent>
        }
        <CardFooter className="flex justify-between">
          {dialogs}
        </CardFooter>
      </Card>
      {
        ticket.access === TICKET_ACCESS.TOKEN && ticket.is_payed && token && <GetInvoiceLink token={token} />
      }
    </div>
  )
}

export function TicketLog({logs}: {logs: Array<TicketLogEntry>}) {
  return (
    <div className="grid grid-cols-1 justify-start">
      {
        logs.map((entry) => {
          return <div className="flex gap-3"> <span className="font-mono flex-none">{formatTimestamp(entry.t)}</span> <span className="flex-none">{entry.log}</span> </div>
      })
      }
    </div>
  );
}
